import React from 'react'
import BlogDetailsBanner from '../PageBanners/BlogDetailsBanner';
import Subscribe from '../Common/Subscribe';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
import Login from '../Common/Login';
 

class LoginPage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render(){
        return (
            <>
                {/* Main Banner */}
                {/* <BlogDetailsBanner /> */}

                {/* Subscribe Area */}
                <Login />
                
                {/* Footer Area */}
                {/* <Footer /> */}
                {/* <GoTop scrollStepInPx="50" delayInMs="16.66" /> */}

                {/* <DemoSidebar /> */}
            </>
        );
    }
}

export default LoginPage;