import React from 'react';
 
class HowWeWork extends React.Component {
    render(){
        return (
            <section id="process" className="how-work ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>維修 <span>流程</span></h2>
                        <p>我們的維修流程高效迅速，只需要短短的幾個步驟，就可以讓你的手機回復如新。</p>
                    </div>
                
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-how-work">
                                <h3>01.</h3>
                                <h4>選擇方案</h4>
                                <p>我們提供不同的維修優惠方案供尊貴的客戶選擇，您可以根據自己的所需選擇合適的服務，並且可以在網上進行預約維修服務。各方案都能在回收手機時享有800/1000/1500元的現金回饋。</p>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-how-work">
                                <h3>02.</h3>
                                <h4>專業維修</h4>
                                <p>在報修前，需在線上智能合約的認證及進行預約，客戶可以選擇寄送方式或到店維修。我們收到您的手機後，會先進行拍攝以確保客戶的權益，然後再安排專業的維修人員替您服務。</p>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div className="single-how-work">
                                <h3>03.</h3>
                                <h4>交付到府</h4>
                                <p>維修完畢後，客戶可以選擇到店自取或寄送，我們會安排快遞運送，客戶可以在線上查詢快遞狀況，運費全免。手機後續可享有均浩電信的一年維修保固。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default HowWeWork;