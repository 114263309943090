import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';
 
class Faq extends React.Component {
    render(){
        return (
            <section id="faq" className="faq-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>常見 <span>問題</span></h2>
                        <p>我們整合了常見問題供您查閱，如有更進我一步問題，請與我們聯絡。</p>
                    </div>
                
                    <div className="row">
                        <div className="col-lg-7 col-md-12">
                            <div className="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                                <Accordion>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                什麼是均浩電訊智能合約？
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            智能合約是指「Smart Contract」，它是一種基於區塊鏈技術的數字資產，每一份智能合約都是唯一的且獨特的。均浩電訊智能合約可用在現實生活中應用，並且可以在區塊鏈上進行交易和持有。持有均浩電訊智能合約有哪些好處？均浩電訊所發行的智能合約在現實生活中與你息息相關。我們的智能合約讓你可以享受專業的 IPhone 維修服務。除此之外，如果想更換手機，可以把舊手機交給我們回收，我們會以回收價再多800/1000/1500元的現金回饋給智能合約持有人。
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                持有均浩電訊智能合約有哪些好處？
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                均浩電訊所發行的智能合約在現實生活中與你息息相關。我們的智能合約讓你可以享受專業的Iphone 維修服務。除此之外，如果想更換手機，可以把舊手機交給我們回收，我們會以回收價再多800/1000/1500元的現金回饋給智能合約持有人。
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                購買智能合約需要使用什麼貨幣？
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                我們是用Polygon的智能合約，接受USDT付款。除此之外，買家可以選擇用現金轉帳或信用卡支付方式購買
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                均浩電訊智能合約是否有保固或支援服務？
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                我們的智能合約都是終身有效，而且服務內容只能用一次。使用均浩維修服務後，可享有一年的免費保固（非人為損壞）。
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Android手機也可以修嗎？
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                目前僅有提供iPhone的維修。
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                維修時程需要多久呢？
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                <li>經線上預約後，我們維修時間約24-36小時。</li>
                                                <li>我們提供可到門市預約維修，或到網上系統進行預約。</li>
                                                <li>如經網上系統進行預約，需要配合物流運輸時間，約3-5個工作天，就能把手機拿回手中。</li>
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                自行送修的地點？
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                均浩電訊門市<br></br>
                                                地址：台北市內湖區文湖街2號<br></br>
                                                電話：02-8771-0883<br></br>
                                                時間：12:00~21:00
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                        
                        <div className="col-lg-5 col-md-12">
                            <div className="image"></div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Faq;