import React from 'react'
import BlogDetailsBanner from '../PageBanners/BlogDetailsBanner';
import RepairSearch from '../Common/RepairSearch';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
// import DemoSidebar from '../Common/DemoSidebar/DemoSidebar';
import { useLocation } from 'react-router-dom';
 
class CheckRepair extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render(){
        return (
            <>
                {/* Main Banner */}
                <BlogDetailsBanner />

                {/* Subscribe Area */}
                <RepairSearch />
                
                {/* Footer Area */}
                <Footer />
                {/* <GoTop scrollStepInPx="50" delayInMs="16.66" /> */}

                {/* <DemoSidebar /> */}
            </>
        );
    }
}

export default CheckRepair;