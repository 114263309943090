import { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { RotatingLines } from  'react-loader-spinner'
import { addRepairOrder } from "../../services/firebase";
import { createRepairNo } from "../../utils/utils";
import axios from "axios";
import moment from "moment";

const RepairModal = ({ show, handleModal, data, reset, clear, plan}) => {
   
    const [state, setState] = useState(false);
    // let d = moment(data.datetime).format('YYYY/MM/DD h:mm A');
    // console.log(d);
    // console.log(data.datetime)
    // console.log("")
    const addRepair = async (order, plan) => {
        if(plan !== "錯誤方案"){
            const no = createRepairNo();
            order.no = no;
            await addRepairOrder(order);
            axios.post("https://getform.io/f/3a5c2230-1dd4-4615-99a7-8110ba7baf4d", {
                預約編號: order.no,
                預約人: order.firstName,
                信箱: order.email,
                電話: order.phone,
                IMEI: order.imei,
                手機型號: order.phone_type,
                維修方案: plan,
                維修項目: order.repair.join(', '),
                收件地址: order.address,
                收件時間: moment(data.datetime).format('YYYY/MM/DD H:mm A')
            })
            setState(true)
        }
    };

    const close = () => {
        clear();
        handleModal();
    }

    return (
        !state ? 
            <Modal
                className="rn-popup-modal placebid-modal-wrapper"
                show={show}
                centered
                onHide={close}
            >
                <Modal.Header>
                    <h3 className="modal-title">維修預約確認</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="bid-content-mid">
                        <h6>預約人: {data.firstName}</h6>
                        <h6>電話: {data.phone}</h6>
                        <h6>電子信箱: {data.email}</h6>
                        <h6>手機型號: {data.phone_type}</h6>
                        <h6>手機IMEI: {data.imei}</h6>
                        <h6>維修方案: {plan}</h6>
                        <h6>維修項目: {!!data.repair && data.repair.join(', ')}</h6>
                        <h6>收件地址: {data.address}</h6>
                        <h6>收件時間: {!!data.datetime && moment(data.datetime).format('YYYY/MM/DD H:mm A')}</h6>
                        <h6>錢包地址: {!!data.wallet && data.wallet.slice(0, 6) + "..." + data.wallet.slice(-4)}</h6>
                        <h6>智能合約編號: {data.nftId}</h6>
                    </div>
                    <div className="d-grid gap-2 col-6 mx-auto">
                        <button type="button" onClick={() => {addRepair(data, plan)}} className="btn btn-primary">送出</button>
                    </div>
                </Modal.Body>
            </Modal>
        : 
        <Modal
                className="rn-popup-modal placebid-modal-wrapper"
                show={show}
                centered
                onHide={close}
            >
                <Modal.Header>
                    <h3 className="modal-title">維修預約成功</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="bid-content-mid">
                        <h6>預約編號: {data.no}</h6>
                        <h6>預約電話: {data.phone}</h6>
                    </div>
                    <div className="d-grid gap-2 col-6 mx-auto">
                        <a href="/">
                            <button type="button" onClick={() => {clear();}} className="btn btn-primary">回到首頁</button>
                        </a>                   
                    </div>
                </Modal.Body>
            </Modal>
    ) 
}

RepairModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
};
export default RepairModal;
