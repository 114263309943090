import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { RotatingLines } from  'react-loader-spinner'
import { ethers } from "ethers";
import { createCheckMac, createHiddenInput, createOrderNo, getCurrencyRate } from '../../utils/utils';
import { addOrder } from '../../services/firebase';
import moment from "moment";

const config = {
    // merchantID: "3002607",
    merchantID: "3148428",
    merchantTradeNo: "",
    merchantTradeDate: "",
    paymentType: "aio",
    totalAmount: 0,
    tradeDesc: "Gotelecom 智能合約",
    itemName: "",
    returnURL: "https://us-central1-gotelecom-nft.cloudfunctions.net/app",
    orderResultURL: "https://us-central1-gotelecom-nft.cloudfunctions.net/app/payment",
    // returnURL: "http://localhost:3000",
    // orderResultURL: "http://localhost:5001/payment",
    choosePayment: "Credit",
    encrpytType: 1,
    checkMacValue: "",
    customField1: "",
    customField2: ""
}

const ConfirmModal = ({ show, handleModal, plan, wallet, detail}) => {
    // console.log("P: ", plan)
    const confirmPayment = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const accounts = await provider.send("eth_requestAccounts", []);
        config.customField1 = accounts[0];
        
        // let price = plan==="A" ? 1680 : plan==="B" ? 1880 : plan==="C" ? 2280 : 2280;
        config.itemName = `Gotelecom 智能合約 ${plan}`
        config.customField2 = plan;

        // const rate = await getCurrencyRate();
        config.merchantTradeNo = createOrderNo();
        config.merchantTradeDate = moment().format('YYYY/MM/DD HH:mm:ss')
        config.totalAmount = Math.round(detail[plan].台幣價格);
        config.checkMacValue = createCheckMac(config);
        // console.log(config)
        // console.log(Qs.stringify(config));
        await addOrder(config.itemName, config.merchantTradeNo, config.merchantTradeDate, accounts[0]);

        const form = document.createElement("form");
        form.method = "POST";
        // form.action = "https://payment-stage.ecpay.com.tw/Cashier/AioCheckOut/V5"; // 這是測試的網址，文件上會寫正式的是哪個網址
        form.action = "https://payment.ecpay.com.tw/Cashier/AioCheckOut/V5";
        createHiddenInput(form, "MerchantID", config.merchantID);
        createHiddenInput(form, "MerchantTradeNo", config.merchantTradeNo);
        createHiddenInput(form, "MerchantTradeDate", config.merchantTradeDate);
        createHiddenInput(form, "PaymentType", "aio");
        createHiddenInput(form, "TotalAmount", config.totalAmount);
        createHiddenInput(form, "TradeDesc", config.tradeDesc);
        createHiddenInput(form, "ItemName", config.itemName);
        createHiddenInput(form, "ReturnURL", config.returnURL);
        createHiddenInput(form, "OrderResultURL", config.orderResultURL);
        createHiddenInput(form, "ChoosePayment", "Credit");
        createHiddenInput(form, "EncryptType", 1);
        createHiddenInput(form, "CustomField1", config.customField1);
        createHiddenInput(form, "CustomField2", config.customField2);
        createHiddenInput(form, "CheckMacValue", config.checkMacValue);
        document.body.appendChild(form);
        form.submit();
    }


    return (
        <Modal
            className="rn-popup-modal placebid-modal-wrapper"
            show={show}
            onHide={handleModal}
            centered
        >
            <Modal.Header>
                <h3 className="modal-title">購買確認</h3>
            </Modal.Header>
            <Modal.Body>
                <div className="bid-content-mid">
                    <h5 style={{padding:"5px"}}>您即將購買 { plan === "A" ? "基礎入門" : plan === "B" ? "精緻高效" : plan === "C" ? "卓越尊享" : ""} 方案。</h5>
                    <h5 style={{padding:"5px"}}>價格: { plan === "A" ? "NT$ 1680" : plan === "B" ? "NT$ 1880" : plan === "C" ? "NT$ 2280" : ""} </h5>
                    <h5 style={{padding:"5px"}}>使用錢包: {!!wallet && wallet.slice(0,6)+"..."+wallet.slice(-4)} </h5>
                </div>
                <div style={{display:"flex", justifyContent:"flex-end"}}>
                    <button className="btn btn-primary" style={{marginLeft:"10px"}} type="button" onClick={handleModal}>取消</button>
                    <button className="btn btn-primary" style={{marginLeft:"10px"}} type="button" onClick={confirmPayment}>確認</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

ConfirmModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
};
export default ConfirmModal;
