import React from 'react';
import { Link } from 'react-router-dom';
import BlogDetailsBanner from '../PageBanners/BlogDetailsBanner';
import image from "../../assets/images/blog-details.jpg";
import clientImageOne from "../../assets/images/client-avatar1.jpg";
import clientImageTwo from "../../assets/images/client-avatar2.jpg";
import clientImageThree from "../../assets/images/client-avatar3.jpg";
import Footer from '../Common/Footer';
 
class BlogDetails extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    
    render(){
        return (
            <React.Fragment>
                <BlogDetailsBanner />

                <section id="blog" className="blog-details-area ptb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 offset-lg-2">
                                <div className="blog-details">
                                    <div className="article-img">
                                        <img 
                                            src={image}
                                            alt="blog-details" 
                                        />
                                        <div className="date">20 <br /> Dec</div>
                                    </div>
                                    
                                    <div className="article-text">
                                        <ul className="category">
                                            <li><Link to="#">IT</Link></li>
                                            <li><Link to="#">Mobile</Link></li>
                                            <li><Link to="#">Marketing</Link></li>
                                            <li><Link to="#">Design</Link></li>
                                            <li><Link to="#">Development</Link></li>
                                        </ul>
                                        
                                        <h4 className="title">How to Be Link Work From Home Professional</h4>
                                        
                                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. In necessitatibus provident facere, reiciendis voluptate dolorem iste consectetur veniam aperiam suscipit ad ipsum et labore Link repellendus debitis explicabo quisquam obcaecati....</p>
                                        
                                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque eum alias debitis suscipit, sint dignissimos minus quisquam recusandae nostrum quas eligendi odit, fugiat eius rem. Cumque, labore placeat! Velit, vitae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, iste? Aut ipsam consequuntur non rem tenetur dolore consequatur ducimus Link labore excepturi quae nisi, quisquam, maxime voluptates magnam aliquid. Cupiditate!</p>
                                        
                                        <p className="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, iste? Aut ipsam consequuntur non rem tenetur dolore consequatur ducimus Link labore excepturi quae nisi, quisquam, maxime voluptates magnam aliquid. Cupiditate!</p>
                                        
                                        <blockquote className="blockquote">
                                            <p className="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, iste? Aut ipsam consequuntur non rem tenetur dolore consequatur ducimus Link labore excepturi quae nisi, quisquam, maxime voluptates magnam aliquid. Cupiditate.</p>
                                        </blockquote>
                                        
                                        <p className="mb-0">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque eum alias debitis suscipit, sint dignissimos minus quisquam recusandae nostrum quas eligendi odit, fugiat eius rem. Cumque, labore placeat! Velit, vitae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, iste? Aut ipsam consequuntur non rem tenetur dolore consequatur ducimus Link labore excepturi quae nisi, quisquam, maxime voluptates magnam aliquid. Cupiditate!</p>
                                        
                                        <div className="author-share">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="article-author">
                                                        <div className="author-img">
                                                            <img 
                                                                src={clientImageOne}
                                                                alt="client"
                                                            />
                                                        </div>

                                                        <div className="author-title">
                                                            <h4>John Smith</h4>
                                                            <span>Back-End Developer</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-lg-6 col-md-6">
                                                    <ul className="social-share">
                                                        <li><Link to="#" data-toggle="tooltip" data-placement="top" title="Share on Facebook"><i className="fa fa-facebook"></i></Link></li>
                                                        <li><Link to="#" data-toggle="tooltip" data-placement="top" title="Share on Twitter"><i className="fa fa-twitter"></i></Link></li>
                                                        <li><Link to="#" data-toggle="tooltip" data-placement="top" title="Share on Linkedin"><i className="fa fa-linkedin"></i></Link></li>
                                                        <li><Link to="#" data-toggle="tooltip" data-placement="top" title="Share on Instagram"><i className="fa fa-instagram"></i></Link></li>
                                                        <li><Link to="#" data-toggle="tooltip" data-placement="top" title="Share on Google Plus"><i className="fa fa-google-plus"></i></Link></li>
                                                        <li><Link to="#" data-toggle="tooltip" data-placement="top" title="Share on Vimeo"><i className="fa fa-vimeo"></i></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="post-controls-buttons">
                                    <div className="controls-left">
                                        <Link to="#"><i className="fa fa-angle-double-left"></i> Prev Post</Link>
                                    </div>

                                    <div className="controls-right">
                                        <Link to="#">Next Post <i className="fa fa-angle-double-right"></i></Link>
                                    </div>
                                </div>
                                
                                <div className="post-comments">
                                    <h3 className="title mt-0">Comments</h3>
                                    <div className="single-comment">
                                        <div className="comment-img">
                                            <img 
                                                src={clientImageTwo} 
                                                alt="client" 
                                            />
                                        </div>
                                        <div className="comment-content">
                                            <h4>Justin Theroux</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro Link voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                            <span>Dec 19, 2018 - 9:10AM</span>
                                            <Link to="#">Reply</Link>
                                        </div>
                                    </div>
                                    
                                    <div className="single-comment left-m">
                                        <div className="comment-img">
                                            <img 
                                                src={clientImageOne} 
                                                alt="client" 
                                            />
                                        </div>
                                        <div className="comment-content">
                                            <h4>Isla Fisher</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro Link voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                            <span>Dec 19, 2018 - 9:10AM</span>
                                            <Link to="#">Reply</Link>
                                        </div>
                                    </div>
                                    
                                    <div className="single-comment">
                                        <div className="comment-img">
                                            <img 
                                                src={clientImageThree} 
                                                alt="client" 
                                            />
                                        </div>
                                        <div className="comment-content">
                                            <h4>Katie Holmes</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro Link voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                            <span>Dec 19, 2018 - 9:10AM</span>
                                            <Link to="#">Reply</Link>
                                        </div>
                                    </div>
                                    
                                    <div className="single-comment mb-0">
                                        <div className="comment-img">
                                            <img 
                                                src={clientImageTwo} 
                                                alt="client" 
                                            />
                                        </div>
                                        <div className="comment-content">
                                            <h4>Tom Cruise</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro Link voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                                            <span>Dec 19, 2018 - 9:10AM</span>
                                            <Link to="#">Reply</Link>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="leave-Link-reply">
                                    <h3 className="title mt-0">Leave Link Reply</h3>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="name" placeholder="Full Name" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <input type="email" className="form-control" name="email" placeholder="E-Mail" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea name="comment" cols="30" rows="5" className="form-control" placeholder="Your Comment"></textarea>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}
 
// class Contact extends React.Component {
//     state = {
//         submitting: false,
//         submitted: false,
//         buttonState: '',
//         formFields: {
//             name: '',
//             email: '',
//             phone: '',
//             text: ''
//         }
//     };

//     onSubmit = (e) => {
//         e.preventDefault();
//         const data = this.state.formFields;
//         fetch('/api/contact', {
//             method: 'post',
//             headers: {
//                 'Accept': 'application/json, text/plain, */*',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(data)
//         }).then(res => {
//             if (res.status === 200) {
//                 this.setState({ submitted: true })
//             }
//             let formFields = Object.assign({}, this.state.formFields);
//             formFields.name = '';
//             formFields.email = '';
//             formFields.phone = '';
//             formFields.text = '';
//             this.setState({formFields});
//         });
//     }

//     nameChangeHandler = (e) => {
//         let formFields = Object.assign({}, this.state.formFields);
//         formFields.name = e.target.value;
//         this.setState({formFields});
//     }

//     emailChangeHandler = (e) => {
//         let formFields = Object.assign({}, this.state.formFields);
//         formFields.email = e.target.value;
//         this.setState({formFields});
//     }

//     phoneChangeHandler = (e) => {
//         let formFields = Object.assign({}, this.state.formFields);
//         formFields.phone = e.target.value;
//         this.setState({formFields});
//     }

//     textChangeHandler = (e) => {
//         let formFields = Object.assign({}, this.state.formFields);
//         formFields.text = e.target.value;
//         this.setState({formFields});
//     }

//     onHideSuccess = () => {
//         this.setState({submitted: false});
//     }

//     successMessage = () => {
//         if (this.state.submitted){
//             return (
//                 <div 
//                     className="alert alert-success alert-dismissible fade show" 
//                     style={{ marginTop: '20px', marginBottom: '0' }}
//                 >
//                     <strong>Thank you!</strong> Your message is send to the owner.
//                     <button 
//                         type="button" 
//                         className="btn-close"
//                         onClick={this.onHideSuccess}
//                     >
//                         <span aria-hidden="true">&times;</span>
//                     </button>
//                 </div>
//             );
//         }
//     }

//     render(){
//         return (
//             <React.Fragment>
//                 <BlogDetailsBanner />
//                 <section id="contact" className="contact-area ptb-80 bg-f6f6f6">
//                     <div className="container">
//                         <div className="section-title">
//                             <h4>Get in Touch</h4>
//                             <h2>Let's <span>Contact</span> Us</h2>
//                             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
//                             <Link to="/blog-details" className="btn btn-primary">Read More</Link>
//                         </div>
                        
//                         <div className="row">
//                             <div className="col-lg-6 col-md-12">
                        
//                                 <iframe 
//                                     title="Google Map" 
//                                     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d699.1289800297188!2d18.077942675805485!3d59.32663213203372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9d5641a77669%3A0xe02744b36aab9f53!2sExercisplan%204%2C%20111%2049%20Stockholm%2C%20Sweden!5e0!3m2!1sen!2sbd!4v1635073543164!5m2!1sen!2sbd" 
//                                     width="100%" 
//                                     height="392" 
//                                     loading="lazy"
//                                 ></iframe>
                                
//                                 <div className="contact-info">
//                                     <ul>
//                                         <li>
//                                             <i className="fa fa-map-marker"></i> Exercisplan 4, 111 49 Stockholm, Sweden
//                                         </li>
//                                         <li>
//                                             <i className="fa fa-envelope"></i> 
//                                             <a href="mailto:addax@gmail.com">addax@gmail.com</a>
//                                         </li>
//                                         <li>
//                                             <i className="fa fa-phone"></i> 
//                                             <a href="tel:124412-2445515">(+124)412-2445515</a>
//                                         </li>
//                                         <li>
//                                             <i className="fa fa-fax"></i> 
//                                             <a href="tel:617-241-60055">617-241-60055</a>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
                            
//                             <div className="col-lg-6 col-md-12">
//                                 <div className="contact-form">
//                                     <h4>Stay Connected</h4>
//                                     <form id="contactForm" onSubmit={this.onSubmit}>
//                                         <div className="row">
//                                             <div className="col-lg-12 col-md-6">
//                                                 <div className="form-group">
//                                                     <label htmlFor="name">Name</label>
//                                                     <input 
//                                                         type="text" 
//                                                         className="form-control" 
//                                                         name="name" 
//                                                         id="name" 
//                                                         required={true}
//                                                         data-error="Please enter your name" 
//                                                         value={this.state.formFields.name}
//                                                         onChange={this.nameChangeHandler}
//                                                     />
//                                                 </div>
//                                             </div>

//                                             <div className="col-lg-12 col-md-6">
//                                                 <div className="form-group">
//                                                     <label htmlFor="name">Name</label>
//                                                     <select className="form-control" >
//                                                         <option disabled={true} value="">
//                                                             --Choose and option--
//                                                         </option>
//                                                         <option value="apple">Apple 🍏</option>
//                                                         <option value="banana">Banana 🍌</option>
//                                                         <option value="kiwi">Kiwi 🥝</option>
//                                                     </select>
//                                                     {/* <input 
//                                                         type="text" 
//                                                         className="form-control" 
//                                                         name="name" 
//                                                         id="name" 
//                                                         required={true}
//                                                         data-error="Please enter your name" 
//                                                         value={this.state.formFields.name}
//                                                         onChange={this.nameChangeHandler}
//                                                     /> */}
//                                                 </div>
//                                             </div>

//                                             <div className="col-lg-12 col-md-6">
//                                                 <div className="form-group">
//                                                     <label htmlFor="email">Email</label>
//                                                     <input 
//                                                         type="email" 
//                                                         className="form-control" 
//                                                         name="email" 
//                                                         id="email" 
//                                                         required={true}
//                                                         data-error="Please enter your email" 
//                                                         value={this.state.formFields.email}
//                                                         onChange={this.emailChangeHandler}
//                                                     />
//                                                 </div>
//                                             </div>

//                                             <div className="col-lg-12 col-md-12">
//                                                 <div className="form-group">
//                                                     <label htmlFor="number">Phone Number</label>
//                                                     <input 
//                                                         type="text" 
//                                                         className="form-control" 
//                                                         name="number" 
//                                                         id="number" 
//                                                         required={true}
//                                                         data-error="Please enter your number" 
//                                                         value={this.state.formFields.phone}
//                                                         onChange={this.phoneChangeHandler}
//                                                     />
//                                                 </div>
//                                             </div>

//                                             <div className="col-lg-12 col-md-12">
//                                                 <div className="form-group">
//                                                     <label htmlFor="message">Message</label>
//                                                     <textarea 
//                                                         name="message" 
//                                                         className="form-control" 
//                                                         id="message" 
//                                                         cols="30" 
//                                                         rows="4" 
//                                                         required={true}
//                                                         data-error="Write your message" 
//                                                         value={this.state.formFields.text}
//                                                         onChange={this.textChangeHandler}
//                                                     />
//                                                 </div>
//                                             </div>

//                                             <div className="col-lg-12 col-md-12">
//                                                 <button type="submit" className="btn btn-primary">Send Message</button>
//                                                 {this.successMessage()} 
//                                             </div>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//                 <Footer />
//             </React.Fragment>
//         );
//     }
// }

export default BlogDetails;