import sha256 from 'crypto-js/sha256';

export const createCheckMac = (config) => {
    // const checkstring = `HashKey=pwFHCqoQZGmho4w6&ChoosePayment=${config.choosePayment}&CustomField1=${config.customField1}&CustomField2=${config.customField2}&EncryptType=1&ItemName=${config.itemName}&MerchantID=${config.merchantID}&MerchantTradeDate=${config.merchantTradeDate}&MerchantTradeNo=${config.merchantTradeNo}&OrderResultURL=${config.orderResultURL}&PaymentType=aio&ReturnURL=${config.returnURL}&TotalAmount=${config.totalAmount}&TradeDesc=${config.tradeDesc}&HashIV=EkRm7iFT261dpevs`;
    const checkstring = `HashKey=JkTGNHaM8Bnns6HF&ChoosePayment=${config.choosePayment}&CustomField1=${config.customField1}&CustomField2=${config.customField2}&EncryptType=1&ItemName=${config.itemName}&MerchantID=${config.merchantID}&MerchantTradeDate=${config.merchantTradeDate}&MerchantTradeNo=${config.merchantTradeNo}&OrderResultURL=${config.orderResultURL}&PaymentType=aio&ReturnURL=${config.returnURL}&TotalAmount=${config.totalAmount}&TradeDesc=${config.tradeDesc}&HashIV=Xmapj4XRrQqlIxwf`;

    // const checkstring = `HashKey=JkTGNHaM8Bnns6HF&ChoosePayment=${config.choosePayment}&CustomField1=${config.customField1}&CustomField2=${config.customField2}&EncryptType=1&ItemName=${config.itemName}&MerchantID=${config.merchantID}&MerchantTradeDate=${config.merchantTradeDate}&MerchantTradeNo=${config.merchantTradeNo}&OrderResultURL=${config.orderResultURL}&PaymentType=aio&ReturnURL=${config.returnURL}&TotalAmount=${config.totalAmount}&TradeDesc=${config.tradeDesc}&HashIV=Xmapj4XRrQqlIxwf`;
    console.log("Initial: ", checkstring)
    var encodedstring = encodeURIComponent(checkstring).replace(/%20/g, "+");;
    encodedstring = encodedstring.toLowerCase();
    console.log("Encoded: ", encodedstring)
    return sha256(encodedstring).toString().toUpperCase();
}

export const createHiddenInput = (form, name, value) => {
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = name;
    hiddenField.value = value;
    form.appendChild(hiddenField);
};

export const createOrderNo = () => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz"
    const randomCharacter = alphabet[Math.floor(Math.random() * alphabet.length)]
    const str = "GTN" + Date.now() + randomCharacter;
    return str.toUpperCase();
}

export const createRepairNo = () => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz"
    const randomCharacter = alphabet[Math.floor(Math.random() * alphabet.length)]
    const str = "R" + Date.now() + randomCharacter;
    return str.toUpperCase();
}

export const createRecycleNo = () => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz"
    const randomCharacter = alphabet[Math.floor(Math.random() * alphabet.length)]
    const str = "RE" + Date.now() + randomCharacter;
    return str.toUpperCase();
}

export const getCurrencyRate = async() => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", "5Ssh8QEMJyA5ESoD5fR6XllRkHzgHV1V");

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
    };

    const res = await fetch("https://api.apilayer.com/fixer/latest?symbols=USD&base=TWD", requestOptions);
    const rate = await res.json();
    return rate.rates.USD
}

export const getCurrentDate = () => {
    let ts = Date.now();

    let date_time = new Date(ts);
    let date = date_time.getDate();
    let month = date_time.getMonth() + 1;
    let year = date_time.getFullYear();

    return (year + "-" + month + "-" + date)
}