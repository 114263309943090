import React, { useState, useEffect } from 'react';
import { ethers } from "ethers";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getNftStatus, getNftPlan, checkNftStatus } from '../services/firebase';
import { getMyNFTs } from '../services/web3';
import RepairModal from '../Modals/repair';
import phoneType from "../data/phone.json"
 
const repair_option = [
    {
        name: "螢幕玻璃"
    },
    {
        name: "更換電池"
    },
    {
        name: "Face ID"
    }
]


const Reserve = () => {

    const { register, handleSubmit, watch, setValue, formState: { errors }, reset } = useForm();
    const [account, setAccount] = useState(null);
    const [nfts, setNfts] = useState();
    const [choosedNFT, setChoosedNFT] = useState();
    const [nftPlan, setNftPlan] = useState();
    const [repairs, setRepairs] = useState([]);
    const [availableRepair, setAvailableRepair] = useState(false)
    const [startDate, setStartDate] = useState();
    const [checkedState, setCheckedState] = useState(
        new Array(3).fill(false)
    );

    const [showModal, setShowModal] = useState(false);

    const handleModal = () => {
        setShowModal((prev) => !prev);
    };

    const clear = () => {
        reset();
        setCheckedState([false, false, false]);
        setStartDate();
    }

    const onSubmit = async (data) => {
        if(!('wallet' in data)){
            alert("請連接你的虛擬錢包");
        } else if (data.nftId === "default") {
            alert("請選擇1個 NFT!");
        } else if (data.phone_type === "default") {
            alert("請選擇您的維修手機型號");
        } else if (availableRepair === true) {
            alert("此 NFT 目前沒有可用的維修項目! 請更換 NFT!");
        } else if (!data.repair || data.repair.length < 1) {
            alert("請至少勾選一個維修項目!");
        } else {
            // await addRepairOrder(data)
            if (await checkNftStatus(data.nftId, data.repair)){
                handleModal();
            }
            else {
                alert("驗證錯誤!");
            }
        }
    };

    const connectToMetamask = async() => {
        // handleMintModal();
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        let accounts = await provider.send("eth_requestAccounts", []);

        console.log(accounts)
        provider.on('accountsChanged', function (accounts) {
            setAccount(accounts[0]);
        });

        setAccount(accounts[0]);
        // setNfts(await getMyNFTs(accounts[0]))
    }

    window.ethereum.on('accountsChanged', async function (accounts) {
        // setNfts();
        window.location.reload(true);
        // setAccount(accounts[0]);
    })

    // const switchAccount = async() => {
    //     console.log(startDate)
    // }

    const getNFTid = () => {
        return nfts.map((nft, index) => {
            return <option key={index} value={nft}>{nft} </option>;
        });
    }

    const getPhone = () => {
        return phoneType[nftPlan].map((p, index) => {
            return <option key={index} value={p.value}>{p.text} </option>;
        });
    }

    const getRepairOption = () => {
        if(repairs.length > 0){
            // console.log(repairs)
            if (repairs[0].status !== "T" && repairs[1].status !== "T" && repairs[2].status !== "T"){
                return <label style={{color: "red"}}>此 NFT 目前沒有可用的維修項目</label>
            }
            else {
                return (repairs.map(({name, status}, index) => {
                    return (
                        <div key={index} className="left-section" style={{marginRight:"10px"}}>
                            <input
                                type="checkbox"
                                id={`custom-checkbox-${index}`}
                                name={name}
                                value={name}
                                disabled={status === "T" ? false : true}
                                {...register("repair", { onChange: (e) => {handleRepair(e)} })}
                                checked={checkedState[index]}
                            />
                            <label htmlFor={`custom-checkbox-${index}`} style={{color: status==="T" ? "ActiveCaption" : "GrayText"}}>{name}</label>
                        </div>
                    )
                }))
            }
        }
    }

    const handleNFTid = async (event) => {
        setChoosedNFT(event.target.value);
    }

    const handleRepair = (event) => {
        console.log(event.target.value)
        const updatedCheckedState = checkedState.map((item, index) => {
            if (event.target.value === "螢幕玻璃" && index === 0) {
                return !item
            } else if (event.target.value === "更換電池" && index === 1) {
                return !item
            } else if (event.target.value === "Face ID" && index === 2) {
                return !item
            } else {
                return item
            }
        });

        if(updatedCheckedState[1] && event.target.value === "更換電池"){
            updatedCheckedState[2] = false
        } else if (updatedCheckedState[2] && event.target.value === "Face ID") {
            updatedCheckedState[1] = false
        }
        // console.log("U: ",updatedCheckedState)
        setCheckedState(updatedCheckedState)
    }

    useEffect(() => {
        // setNfts();
        if(!!choosedNFT){
            getNftStatus(choosedNFT)
            .then( status => {
                console.log("S: ", status)
                if(!status){
                    return []
                } else {
                    const options = []
                    
                    status.forEach((i, index) => {
                        if(index === 0){
                            options.push({
                                name: repair_option[0].name,
                                status: i
                            })
                        } else if(index === 1){
                            options.push({
                                name: repair_option[1].name,
                                status: i
                            })
                            options.push({
                                name: repair_option[2].name,
                                status: i
                            })
                        }
                    })
                    return options
                }
            })
            .then( res => {
                console.log("O: ",res)
                setRepairs(res)
            })
            // console.log("S: ", status)

            getNftPlan(choosedNFT)
            .then( plan => {
                setNftPlan(plan);
            })
        }
    }, [choosedNFT]);

    useEffect(() => {
        
        if(!!account){
            setNfts();
            console.log(account);
            getMyNFTs(account)
            .then( res => {
                setNfts(res)
            })
        }     
    }, [account])

    return (
        <>
        <section id="contact" className="contact-area ptb-80 bg-f6f6f6">
            <div className="container">                
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="contact-form">
                            <h4>預約維修</h4>
                            <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="wa">錢包地址</label>
                                            {/* { !!account && 
                                                <button className="btn btn-primary" type="button" style={{marginLeft:"10px", marginBottom:"20px"}} onClick={switchAccount}>switch account</button>
                                            } */}
                                            { !!!account ?
                                                <button className="btn btn-primary" type="button" style={{marginLeft:"10px", marginBottom:"20px"}} onClick={connectToMetamask} >Connect</button> :
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="wallet" 
                                                    id="wallet" 
                                                    required={true}
                                                    data-error="Please enter your name" 
                                                    value={account}
                                                    autoComplete="new-password"
                                                    {...register("wallet")}
                                                />
                                            }
                                        </div>
                                    </div>
                                    { !!account &&
                                    <>
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="nft">智能合約編號</label>
                                                <select className="form-control" defaultValue={'default'} {...register("nftId", { onChange: (e) => {handleNFTid(e)} })}>
                                                    <option disabled={true} value="default">
                                                        -- 選擇 智能合約 --
                                                    </option>
                                                    { !!nfts && getNFTid() }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="nft">手機型號</label>
                                                <select className="form-control" defaultValue={'default'} {...register("phone_type")}>
                                                    <option disabled={true} value="default">
                                                        -- 選擇型號 --
                                                    </option>
                                                    { !!nftPlan && getPhone() }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="repair">維修項目</label>
                                                <div style={{display: "flex"}}>
                                                    {getRepairOption()}                                                 
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <div style={{display: "flex"}}>
                                                    <label htmlFor="firstName">手機 IMEI</label>
                                                    <ErrorMessage errors={errors} name="imei" as="p" style={{marginBottom:"5px"}}/>
                                                </div>
                                                
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="imei" 
                                                    id="imei" 
                                                    // required={true}
                                                    data-error="Please enter your imei" 
                                                    {...register("imei", { 
                                                        required: "＊必填" , 
                                                        pattern: {
                                                            value: /[0-9]{15}/,
                                                            message: '＊請填入15位數字' // JS only: <p>error message</p> TS only support string
                                                        },
                                                        maxLength : {
                                                            value: 15,
                                                            message: '＊請填入15位數字' // JS only: <p>error message</p> TS only support string
                                                        },
                                                        minLength: {
                                                            value: 15,
                                                            message: '＊請填入15位數字' // JS only: <p>error message</p> TS only support string
                                                        }
                                                        
                                                    })}
                                                    autoComplete="new-password"
                                                />
                                            </div>
                                        </div>
                                    </>
                                    }

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <div style={{display: "flex"}}>
                                                <label htmlFor="firstName">姓名</label>
                                                <ErrorMessage errors={errors} name="firstName" as="p" style={{marginBottom:"5px"}}/>
                                            </div>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="firstName" 
                                                id="firstName" 
                                                data-error="Please enter your name" 
                                                {...register("firstName", {
                                                    required: "＊必填"
                                                })}
                                                autoComplete="new-password"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <div style={{display: "flex"}}>
                                                <label htmlFor="email">電子信箱</label>
                                                <ErrorMessage errors={errors} name="email" as="p" style={{marginBottom:"5px"}}/>
                                            </div>
                                            <input 
                                                type="email" 
                                                className="form-control" 
                                                name="email" 
                                                id="email" 
                                                data-error="Please enter your email" 
                                                {...register("email", {
                                                    required: "＊必填"
                                                })}
                                                autoComplete="new-password"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <div style={{display: "flex"}}>
                                                <label htmlFor="number">電話號碼</label>
                                                <ErrorMessage errors={errors} name="phone" as="p" style={{marginBottom:"5px"}}/>
                                            </div>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="number" 
                                                id="number" 
                                                data-error="Please enter your number" 
                                                {...register("phone", {
                                                    required: "＊必填"
                                                })}
                                                autoComplete="new-password"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <div style={{display: "flex"}}>
                                                <label htmlFor="address">收件地址</label>
                                                <ErrorMessage errors={errors} name="address" as="p" style={{marginBottom:"5px"}}/>
                                            </div>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="address" 
                                                id="address" 
                                                data-error="Please enter your address" 
                                                {...register("address",{
                                                    required: "＊必填"
                                                })}
                                                autoComplete="new-password"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <div style={{display: "flex"}}>
                                                <label htmlFor="number">收件時間</label>
                                                <ErrorMessage errors={errors} name="datetime" as="p" style={{marginBottom:"5px"}}/>
                                            </div>
                                            <DatePicker
                                                {...register("datetime", { required: "＊必填" })}
                                                name="datetime"
                                                className={"form-control"}
                                                selected={startDate}
                                                onChange={val => {
                                                    setStartDate(val);
                                                    setValue("datetime", val);
                                                }}
                                                showTimeSelect
                                                timeFormat="HH:mm a"
                                                timeIntervals={15}
                                                timeCaption="time"
                                                dateFormat="MM-dd-yyyy h:mm a"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="btn btn-primary">確認</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <RepairModal
            show={showModal}
            handleModal={handleModal}
            data={watch()}
            reset={reset}
            clear={clear}
            plan={nftPlan === "A" ? "基礎入門" : nftPlan === "B" ? "精緻高效" : nftPlan === "C" ? "卓越尊享" : "錯誤方案"}
        />
        </>
    );
    
}

export default Reserve;