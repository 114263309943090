import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { RotatingLines } from  'react-loader-spinner'


const MintModal = ({ show, handleModal, owner, doc}) => {
    
    return (
        <Modal
            className="rn-popup-modal placebid-modal-wrapper"
            show={show}
            centered
        >
            <Modal.Header>
                <h3 className="modal-title">購買智能合約</h3>
            </Modal.Header>
            <Modal.Body>
                <div className="bid-content-mid">
                    <h6>正在購買智能合約，請稍候。</h6>
                </div>
                <div className="bit-continue-button">
                    {/* <Button 
                        color="primary-alta"
                        size="medium"
                        className="mt--10"
                        onClick={handleModal}
                    >
                        Cancel
                    </Button> */}
                    <div className="waiting">
                        <RotatingLines 
                            strokeWidth="3"
                            animationDuration="0.75"
                            width="60"
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

MintModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
};
export default MintModal;
