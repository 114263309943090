import { useState } from "react";
import { useHistory } from "react-router-dom";
import { CheckUserLogin } from "../services/firebase";
// import Dashboard from "./Dashboard";

const Login = () => {
    const history = useHistory();
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(username, password);
        alert(await CheckUserLogin(username, password))
        // e.preventDefault();
        // const account = users.find((user) => user.username === username);
        if (await CheckUserLogin(username, password)) {
            setauthenticated(true)
            localStorage.setItem("_ath", true);
            history.push("/backend-tool");
        }
    };
    return (
        <div style={{margin:"50px"}}>
            <p>Welcome Back</p>
            <form onSubmit={handleSubmit}>
                <label>帳號：</label>
                <input
                    type="text"
                    name="Username"
                    value={username}
                    onChange={(e) => setusername(e.target.value)}
                />
                <label>密碼：</label>
                <input
                    type="password"
                    name="Password"
                    onChange={(e) => setpassword(e.target.value)}
                />
                <input type="submit" value="登入" />
            </form>
        </div>
    )
};

export default Login;