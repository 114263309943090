import * as React from 'react';
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const RecycleDetailModal = ({ show, handleModal, data}) => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    console.log("Test", data)
    return (
        <Modal
            className="rn-popup-modal placebid-modal-wrapper"
            show={show}
            onHide={handleModal}
            centered
        >
            <Modal.Header>
                <h3 className="modal-title">{!!data && data.product}</h3>
            </Modal.Header>
            <Modal.Body>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                    <Tab label="等級 A" {...a11yProps(0)} />
                    <Tab label="等級 B" {...a11yProps(1)} />
                    <Tab label="等級 C" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <TableContainer>
                        <Table sx={{ minWidth: 100 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>容量大小</TableCell>
                                <TableCell align="right">回收價格</TableCell>
                                {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {!! data && data.level.A.map((row) => (
                                <TableRow
                                key={row.size}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.size}
                                </TableCell>
                                <TableCell align="right">NT$ {row.price}</TableCell>
                                {/* <TableCell align="right">{row.fat}</TableCell>
                                <TableCell align="right">{row.carbs}</TableCell>
                                <TableCell align="right">{row.protein}</TableCell> */}
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={value} index={1}>
                <TableContainer>
                        <Table sx={{ minWidth: 100 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>容量大小</TableCell>
                                <TableCell align="right">回收價格</TableCell>
                                {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {!! data && data.level.B.map((row) => (
                                <TableRow
                                key={row.size}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.size}
                                </TableCell>
                                <TableCell align="right">NT$ {row.price}</TableCell>
                                {/* <TableCell align="right">{row.fat}</TableCell>
                                <TableCell align="right">{row.carbs}</TableCell>
                                <TableCell align="right">{row.protein}</TableCell> */}
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={value} index={2}>
                <TableContainer>
                        <Table sx={{ minWidth: 100 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>容量大小</TableCell>
                                <TableCell align="right">回收價格</TableCell>
                                {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {!! data && data.level.C.map((row) => (
                                <TableRow
                                key={row.size}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.size}
                                </TableCell>
                                <TableCell align="right">NT$ {row.price}</TableCell>
                                {/* <TableCell align="right">{row.fat}</TableCell>
                                <TableCell align="right">{row.carbs}</TableCell>
                                <TableCell align="right">{row.protein}</TableCell> */}
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                </Box>
            </Modal.Body>
        </Modal>
    );
}

RecycleDetailModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
};
export default RecycleDetailModal;
