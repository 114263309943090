import React from 'react';
import { Link } from 'react-router-dom';
 
class Welcome extends React.Component {
    render(){
        return (
            <section id="welcome" className="welcome-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        {/* <h4>We are creative</h4> */}
                        <h2>歡迎來到 <span>均浩電訊</span></h2>
                        <p>均浩電訊Gotelecom提供便捷專業的手機維修服務，此外我們提供市場最高價的手機回收方案，確保我們的客戶享有最高品質的終身服務。</p>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-box">
                                <i className="fa fa-wrench icon"></i>
                                <h3>專業維修</h3>
                                <p>均浩電訊Gotelecom 提供專業維修服務，讓您的設備能夠運作如新。我們的技術專家具有豐富的經驗，能夠快速有效地解決您的問題。</p>
                                {/* <Link to="#" title="Read More" className="link-btn"><i className="fa fa-arrow-right"></i></Link> */}
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-box">
                                <i className="fa fa-shield icon"></i>
                                <h3>長效保固</h3>
                                <p>均浩電訊Gotelecom 提供全面的保固服務，以確保您的產品能夠長久運作。我們承諾保固期間內免費為您進行檢修。</p>
                                {/* <Link to="#" title="Read More" className="link-btn"><i className="fa fa-arrow-right"></i></Link> */}
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div className="single-box">
                                <i className="fa fa-tag icon"></i>
                                <h3>優惠價格</h3>
                                <p>均浩電訊Gotelecom 手機保固方案主打同等級市場最低價，多元的保固方案選擇，節省您的荷包。</p>
                                {/* <Link to="#" title="Read More" className="link-btn"><i className="fa fa-arrow-right"></i></Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Welcome;