import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link, withRouter  } from 'react-router-dom';
import { HashLink, NavHashLink } from 'react-router-hash-link';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -85; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

class Navigation extends React.Component {
    state = {
        collapsed: true,
    };


    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    componentDidMount() {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 50) {
                elementId.classList.add("is-sticky");
                window.history.pushState("", document.title, window.location.pathname);
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);

        // if (!(window.location.pathname === '/blog' || window.location.pathname === '/blog-details' || window.location.pathname === '/reserve' || window.location.pathname === '/mint' )){
        //     let mainNavLinks = document.querySelectorAll("nav ul li a");

        //     window.addEventListener("scroll", () => {
        //         let fromTop = window.scrollY;
            
        //         mainNavLinks.forEach(link => {
        //         let section = document.querySelector(link.hash);
            
        //             if (
        //                 section.offsetTop <= fromTop &&
        //                 section.offsetTop + section.offsetHeight > fromTop
        //             ) {
        //                 link.classList.add("active");
        //             } else {
        //                 link.classList.remove("active");
        //             }
        //         });
        //     });
        // }
    }

    goToId = (e) => {
        window.location.hash = e;
        window.location.refresh(true);
    }

    renderMenus = () => {
        if (window.location.pathname === '/blog' || window.location.pathname === '/blog-details' || window.location.pathname === '/reserve' || window.location.pathname === '/check' || window.location.pathname === '/backend-tool' || window.location.pathname === '/recycle'){
            return (
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <NavHashLink onClick={this.toggleNavbar} className="nav-link" to="/">首頁</NavHashLink>
                    </li>
                    <li className="nav-item">
                        <NavHashLink onClick={this.toggleNavbar} scroll={ el => scrollWithOffset(el)} className="nav-link" smooth to="/#about">關於</NavHashLink>
                    </li>
                    <li className="nav-item">
                        <NavHashLink onClick={this.toggleNavbar} scroll={ el => scrollWithOffset(el)} className="nav-link" smooth to="/#services">服務</NavHashLink>
                    </li>
                    <li className="nav-item">
                        <NavHashLink onClick={this.toggleNavbar} scroll={ el => scrollWithOffset(el)} className="nav-link" smooth to="/#process">流程</NavHashLink>
                    </li>
                    <li className="nav-item">
                        <NavHashLink onClick={this.toggleNavbar} scroll={ el => scrollWithOffset(el)} className="nav-link" smooth to="/#price">價格</NavHashLink>
                    </li>
                    <li className="nav-item">
                        <NavHashLink onClick={this.toggleNavbar} scroll={ el => scrollWithOffset(el)} className="nav-link" smooth to="/#faq">常見問題</NavHashLink>
                    </li>
                    <li className="nav-item">
                        <NavHashLink onClick={this.toggleNavbar} className="nav-link" to="/recycle">回收方案</NavHashLink>
                    </li>
                    <li className="nav-item">
                        <NavHashLink onClick={this.toggleNavbar} className="nav-link" to="/check">查詢預約</NavHashLink>
                    </li>
                    <li className="nav-item">
                        <NavHashLink onClick={this.toggleNavbar} className="nav-link" to="/reserve">預約維修</NavHashLink>
                    </li>
                </ul>
            );
        }

        if (window.location.pathname === '/login'){
            return <></>
        }

        return (
            <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                    <AnchorLink onClick={this.toggleNavbar} offset={85} className="nav-link" href="#home1">首頁</AnchorLink>
                </li>
                <li className="nav-item">
                    <AnchorLink onClick={this.toggleNavbar} offset={85} className="nav-link" href="#about">關於</AnchorLink>
                </li>
                {/* <li className="nav-item">
                    <AnchorLink onClick={this.toggleNavbar} offset={() => 85} className="nav-link" href="#team">Team</AnchorLink>
                </li> */}
                <li className="nav-item">
                    <AnchorLink onClick={this.toggleNavbar} offset={85} className="nav-link" href="#services">服務</AnchorLink>
                </li>
                <li className="nav-item">
                    <AnchorLink onClick={this.toggleNavbar} offset={85} className="nav-link" href="#process">流程</AnchorLink>
                </li>
                <li className="nav-item">
                    <AnchorLink onClick={this.toggleNavbar} offset={85} className="nav-link" href="#price">價格</AnchorLink>
                </li>
                <li className="nav-item">
                    <AnchorLink onClick={this.toggleNavbar} offset={85} className="nav-link" href="#faq">常見問題</AnchorLink>
                </li>
                <li className="nav-item">
                    <NavHashLink onClick={this.toggleNavbar} className="nav-link" to="/recycle">回收方案</NavHashLink>
                </li>
                <li className="nav-item">
                    <NavHashLink onClick={this.toggleNavbar} className="nav-link" to="/check">查詢預約</NavHashLink>
                </li>
                <li className="nav-item">
                    <NavHashLink onClick={this.toggleNavbar} className="nav-link" to="/reserve">預約維修</NavHashLink>
                </li>
            </ul>
        );
    }

    render(){
        const { collapsed } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'navbar-collapse collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        return (
            <nav id="navbar" className="navbar navbar-expand-md navbar-light bg-light header-sticky">
                <div className="container">
                    <a 
                        className="navbar-brand" 
                        href="/"
                    >
                        { window.location.pathname !== '/login' && 
                        <>
                            <span>均浩電訊 </span>Gotelecom
                        </>
                        }
                       
                    </a>

                    <button 
                        onClick={this.toggleNavbar}
                        className={classTwo} 
                        type="button" 
                        data-toggle="collapse" 
                        data-target="#navbarSupportedContent" 
                        aria-controls="navbarSupportedContent" 
                        aria-expanded="false" 
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={classOne} id="navbarSupportedContent">
                        {this.renderMenus()}
                    </div>
                </div>
            </nav>
        );
    }
}
 
export default withRouter(Navigation);