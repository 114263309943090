import React, {useState} from 'react'
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import { useForm } from "react-hook-form";
import { getRepair } from '../services/firebase';

const RepairSearch = () =>  {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [search, setSearch] = useState([]);
    const [noresult, setNoresult] = useState(false);
    const onSubmit = async (data) => {
        // event.preventDefault();
        console.log(data);
        const res = await getRepair(data.phone);

        if(res.length === 0){
            setNoresult(true);
        } else {
            setNoresult(false);
        }
        console.log(res);
        setSearch(res);
    }

    return (
        <>
            <section className="subscribe-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="newsletter">
                                <h4>維修查詢</h4>
                                <form onSubmit={handleSubmit(onSubmit)} className="newsletter-form" data-toggle="validator">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="手機號碼" 
                                        name="phone" 
                                        autoComplete="off"
                                        {...register("phone")}
                                        // onChange={{}}
                                    />
                                    <button className="btn btn-primary" type="submit">查詢</button>
                                </form>
                                <div id="repair-search" style={{marginTop:"20px"}}>
                                    { noresult && 
                                        <div>沒有相關訂單</div>
                                    }
                                    { search.map((data) => {
                                        return (
                                            <Card key={data.預約編號} style={{margin:"10px"}}>
                                                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                                                <Card.Body>
                                                    <Card.Title>預約編號 {data.預約編號}</Card.Title>
                                                    <ListGroup className="list-group-flush">
                                                        <ListGroup.Item>
                                                            <i className="fa fa-check" style={{color: data.訂單狀態.length >= 1 ? "green" : "lightgrey"}}></i> 預約完成
                                                            <span>{data.訂單狀態[0].時間}</span>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <i className={data.訂單狀態.length >= 2 ? "fa fa-check" : "fa fa-arrow-right"} style={{color: data.訂單狀態.length >= 2 ? "green" : "lightgrey"}}></i> 快遞收件
                                                            { data.訂單狀態.length > 1 &&
                                                                <>
                                                                    <span>{data.訂單狀態[1].時間}</span>
                                                                    <span>取件編號: <a href="https://htm.sf-express.com/tw/tc/dynamic_function/waybill/" target="_blank" rel="noreferrer">{data.訂單狀態[1].收件編號}</a></span>
                                                                </> 
                                                            
                                                            }
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <i className={data.訂單狀態.length > 2 ? "fa fa-check" :  data.訂單狀態.length === 2 ? "fa fa-arrow-right" : "fa fa-ellipsis-h"} style={{color: data.訂單狀態.length >= 3 ? "green" : "lightgrey"}}></i> 檢查手機
                                                            { data.訂單狀態.length > 2 &&
                                                                <span>{data.訂單狀態[2].時間}</span> 
                                                            }
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <i className={data.訂單狀態.length > 3 ? "fa fa-check" :  data.訂單狀態.length === 3 ? "fa fa-arrow-right" : "fa fa-ellipsis-h"} style={{color: data.訂單狀態.length >= 4 ? "green" : "lightgrey"}}></i> 維修手機
                                                            { data.訂單狀態.length > 3 &&
                                                                <span>{data.訂單狀態[3].時間}</span> 
                                                            }
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <i className={data.訂單狀態.length > 4 ? "fa fa-check" :  data.訂單狀態.length === 4 ? "fa fa-arrow-right" : "fa fa-ellipsis-h"} style={{color: data.訂單狀態.length >= 5 ? "green" : "lightgrey"}}></i> 手機寄回
                                                            { data.訂單狀態.length > 4 &&
                                                                <>
                                                                    <span>{data.訂單狀態[4].時間}</span>
                                                                    <span>送件編號: <a href="https://htm.sf-express.com/tw/tc/dynamic_function/waybill/" target="_blank" rel="noreferrer">{data.訂單狀態[1].收件編號}</a></span>
                                                                </>                                                     
                                                            }
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                    {/* <Button variant="primary">Go somewhere</Button> */}
                                                </Card.Body>
                                            </Card>
                                        )  
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default RepairSearch;