import { createRepairNo } from "../../utils/utils";
import { doc, setDoc, getDoc, query, where, getDocs, collection, updateDoc, arrayUnion } from "firebase/firestore"
import { db } from "../init"
import { getMyNFTs } from "../web3";
import moment from "moment";
import image from "../../data/nft_image.json"


export const addOrder = async(name, order, date, wallet) => {
    await setDoc(doc(db, "NFT訂單", order), {
        訂單編號: order,
        訂單日期: date,
        商品名稱: name,
        錢包: wallet,
        付款方式: "信用卡"
      });
}


export const getNftStatus = async(nftId) => {
    const docSnap = await getDoc(doc(db, "NFT", nftId));
    if (docSnap.exists() && !! docSnap.data().repair) {
        // console.log("Document data:", docSnap.data());
        return docSnap.data().repair;
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return false
      }
}

export const getNftPlan = async(nftId) => {
    const docSnap = await getDoc(doc(db, "NFT", nftId));
    if (docSnap.exists() && !!docSnap.data().plan) {
        // console.log("Document data:", docSnap.data());
        return docSnap.data().plan;
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return false
      }
}

export const addRepairOrder = async (order) => {
  // const no = createRepairNo();
  await setDoc(doc(db, "維修預約", order.no), {
    預約編號: order.no,
    預約日期: moment().format('YYYY/MM/DD HH:mm:ss'),
    收件地址: order.address,
    收件時間: moment(order.datetime).format('YYYY/MM/DD h:mm A'),
    電話號碼: order.phone,
    電子信箱:  order.email,
    手機型號: order.phone_type,
    手機IMEI: order.imei,
    維修項目: order.repair,
    NFT編號: order.nftId,
    錢包: order.wallet,
    訂單狀態: [
      {
        狀態: "預約成功",
        時間: moment().format('YYYY/MM/DD HH:mm:ss')
      }
    ]
  });

  let status = await getNftStatus(order.nftId);
  order.repair.forEach(name => {
    if(name === "螢幕玻璃"){
      status[0] = "P";
    }
    else if (name === "更換電池" || name === "Face ID"){
      status[1] = "P";
    }
    else {
      status = ["F", "F", "T"];
    }
  })

  await updateDoc(doc(db, "NFT", order.nftId), {
    repair: status
  })

}

export const addRecycleOrder = async (no, order, checked) => {
  // const no = createRepairNo();
  if(checked && order.nftId !== "default") {
    let status = await getNftStatus(order.nftId);
    if (status === false) {
      return false
    } else {
      status[2] = "P";
    }
    await setDoc(doc(db, "回收預約", no), {
      預約編號: no,
      預約日期: moment().format('YYYY/MM/DD HH:mm:ss'),
      收件地址: order.address,
      收件時間: moment(order.datetime).format('YYYY/MM/DD h:mm A'),
      電話號碼: order.phone,
      電子信箱:  order.email,
      NFT編號: order.nftId,
      錢包: order.wallet,
      訂單狀態: [
        {
          狀態: "預約成功",
          時間: moment().format('YYYY/MM/DD HH:mm:ss')
        }
      ]
    });
    await updateDoc(doc(db, "NFT", order.nftId), {
      repair: status
    })

  } else {
    await setDoc(doc(db, "回收預約", no), {
      預約編號: no,
      預約日期: moment().format('YYYY/MM/DD HH:mm:ss'),
      收件地址: order.address,
      收件時間: moment(order.datetime).format('YYYY/MM/DD h:mm A'),
      電話號碼: order.phone,
      電子信箱: order.email,
      訂單狀態: [
        {
          狀態: "預約成功",
          時間: moment().format('YYYY/MM/DD HH:mm:ss')
        }
      ]
    });
  }

  return true
}

export const checkNftStatus = async (id, repair) => {

  let status = await getNftStatus(id);
  console.log("Status: ", status)
  for(var x = 0 ; x < repair.length ; x++){
    if(repair[x] === "螢幕玻璃" && status[0] !== "T") {
      console.log(1)
      return false
    }
    else if (repair[x] === "更換電池" && status[1] !== "T"){
      console.log(2)
      return false
    }
    else if (repair[x] === "Face ID" && status[1] !== "T"){
      console.log(3)
      return false
    }
  }
  return true
}

export const getRepair = async (phone) => {
  let search = [];
  const q = query(collection(db, "維修預約"), where("電話號碼", "==", phone));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    search.push(doc.data())
    console.log(doc.id, " => ", doc.data());
  });
  return search
}

export const getRepairOrder = async (order) => {
  const docRef = doc(db, "維修預約", order);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}

export const addRepairorder = async (step, order, no, data) => {
  const docRef = doc(db, "維修預約", order);
  switch (step) {
    case 2 :
      await updateDoc(docRef, {
        訂單狀態: arrayUnion({
          時間: moment().format('YYYY/MM/DD HH:mm:ss'),
          狀態: "預約收件",
          收件編號: no,
        })
      });
      break;
    case 3 :
      await updateDoc(docRef, {
        訂單狀態: arrayUnion({
          時間: moment().format('YYYY/MM/DD HH:mm:ss'),
          狀態: "完成檢查",
        })
      });
      break;
    case 4 :
      await updateDoc(docRef, {
        訂單狀態: arrayUnion({
          時間: moment().format('YYYY/MM/DD HH:mm:ss'),
          狀態: "完成維修",
        })
      });

      // 更新 NFT Repair Status 
      let status = await getNftStatus(data.NFT編號);
      console.log(data, status)
      data.維修項目.forEach((i) => {
        if(i === "螢幕玻璃"){
          status[0] = "U"
        } else if (i === "更換電池" || i === "Face ID") {
          status[1] = "U"
        }
      })
      const nftRef = doc(db, "NFT", data.NFT編號);
      await updateDoc(nftRef, {
        repair: status
      })

      updateNFTMetadata(data.NFT編號, data.維修項目)
      
      break;
    case 5 :
      await updateDoc(docRef, {
        訂單狀態: arrayUnion({
          時間: moment().format('YYYY/MM/DD HH:mm:ss'),
          狀態: "手機寄回",
          收件編號: no,
        })
      });
      break;
    default :
  }
}

export const checkNftOwners = async(nfts) => {
  // const nfts = await getMyNFTs(ad);
  // console.log(nfts)
  const a = nfts.map(d => {
    return(Number(d.tokenId));
  })

  let res = [];
  for(var x = 0 ; x < a.length ; x+=10){
    const q = query(collection(db, "NFT"), where("id", "in" , a.slice(x, x+10)));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      res.push(doc.data().id)
      // console.log(doc.id, " => ", doc.data());
    });
  }
  // console.log(res)
  return res
}

export const checkNftRecycle = async(nfts) => {
  const a = nfts.map(d => {
    return(Number(d.tokenId));
  })

  let res = [];
  for(var x = 0 ; x < a.length ; x+=10){
    const q = query(collection(db, "NFT"), where("id", "in" , a.slice(x, x+10)));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      if(doc.data().repair[2] === "T"){
        res.push(doc.data().id)
      }
      // console.log(doc.id, " => ", doc.data());
    });
  }
  // console.log(res)
  return res
}


export const CheckUserLogin = async (username, password) => {
  const docRef = doc(db, "帳號", "admin");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const d = docSnap.data();
    if(d.account === username && d.password === password){
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const updateNFTMetadata = async (nftId) => {
  const docRef = doc(db, "NFT", nftId);
  const docSnap = await getDoc(docRef);
  const nftMeta = docSnap.data();
  let s = "";
  s += nftMeta.plan;
  nftMeta.repair.forEach(i => {
    if(i === "U"){
      s += "0";
    } else {
      s += "1";
    }
  })

  await updateDoc(docRef, {
    image: image[s]
  })
}

export const getPlanDetail = async () => {
  const plan = {}
  const querySnapshot = await getDocs(collection(db, "NFT方案"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    plan[doc.id] = doc.data();
    console.log(doc.id, " => ", doc.data());
  });
  // console.log(plan);
  return plan
}

export const getRecyclePrice = async() => {
  const recycle = {}
  const querySnapshot = await getDocs(collection(db, "回收價格"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    recycle[doc.id] = doc.data().價格.reverse();
    console.log(recycle);
  });
  return recycle
}