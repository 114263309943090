import React from 'react';
import BlogBanner1 from '../PageBanners/BlogBanner1';
import Content from '../Blog/Content';
import Footer from '../Common/Footer';
import Tool from '../Common/Tool';
 
class BackendTool extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render(){
        return (
            <>
                <BlogBanner1 />

                <Tool />
                
                {/* <Footer /> */}
            </>
        );
    }
}
 
export default BackendTool;