import React, {useState, useEffect} from "react";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import Accordion from "react-bootstrap/Accordion";
import ListGroup from 'react-bootstrap/ListGroup';
import { getRepairOrder, addRepairorder } from "../services/firebase";

 
const Tool = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [order, setOrder] = useState();
    const [search, setSearch] = useState();
    const [trans, setTrans] = useState();

    const onSubmit = async (data) => {
        console.log(data)
        setOrder(data.order);
        const res = await getRepairOrder(data.order);
        setSearch(res);
        console.log(res.預約編號);
        console.log(res.維修項目);
    }

    const handleTranChange = (event) => {
        setTrans(event.target.value);
    };
    
    const tranSubmit = async () => {
        if(!!trans){
            await addRepairorder(2, order, trans, null)
            const res = await getRepairOrder(order);
            setSearch(res);
            console.log(order, res)
        }
        else {
            console.log("error")
        }
    }

    const checkSubmit = async () => {
        await addRepairorder(3, order, null, null)
        const res = await getRepairOrder(order);
        setSearch(res);
        console.log(order, res)
    }

    const repairSubmit = async () => {
        await addRepairorder(4, order, null, search)
        const res = await getRepairOrder(order);
        setSearch(res);
        console.log(order, res)
    }

    const tranBackSubmit = async () => {
        if(!!trans){
            await addRepairorder(5, order, trans, null)
            const res = await getRepairOrder(order);
            setSearch(res);
            console.log(order, res)
        }
        else {
            console.log("error")
        }
    }

    const edit = () => {

    }

    const [authenticated, setauthenticated] = useState(localStorage.getItem("_ath"));
    // useEffect(() => {
    //     const loggedInUser = localStorage.getItem("_ath");
    //     if (loggedInUser) {
    //         setauthenticated(loggedInUser);
    // }}, []);

    if (!authenticated){
        return (
            <Redirect replace to="/login"/>
        )
    }
    else {
        return (
            <>
            <section className="subscribe-area ptb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="backend">
                                    <h4>預約單號</h4>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="預約單號" 
                                            name="order" 
                                            autoComplete="off"
                                            {...register("order")}
                                            // onChange={{}}
                                        />
                                        <button className="btn btn-primary" type="submit">搜尋</button>
                                    </form>
                                    { !!search &&
                                        <div style={{marginTop:"20px"}}>
                                            {/* <span className="fa fa-pencil" style={{display:"flex", justifyContent:"flex-end", marginBottom:"5px", cursor:"pointer", color:"GrayText"}} onClick={edit}>修改訂單</span> */}
                                            <Accordion defaultActiveKey={['0']} alwaysOpen>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header># {search.預約編號}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ListGroup className="list-group-flush">
                                                            <ListGroup.Item>
                                                                <i className="fa fa-check" style={{color: search.訂單狀態.length >= 1 ? "green" : "lightgrey"}}></i> 預約完成
                                                                <span>{search.訂單狀態[0].時間}</span>
                                                            </ListGroup.Item>
                                                            <ListGroup.Item>
                                                                <i className={search.訂單狀態.length >= 2 ? "fa fa-check" : "fa fa-arrow-right"} style={{color: search.訂單狀態.length >= 2 ? "green" : "lightgrey"}}></i> 快遞收件
                                                                { search.訂單狀態.length > 1 ? 
                                                                    <>
                                                                        <span>{search.訂單狀態[1].時間}</span>
                                                                        <span>取件編號: <a href="https://htm.sf-express.com/tw/tc/dynamic_function/waybill/" target="_blank" rel="noreferrer">{search.訂單狀態[1].收件編號}</a></span>
                                                                    </> :
                                                                    <div>
                                                                        <input
                                                                            type="text" 
                                                                            placeholder="順豐單號" 
                                                                            name="trans" 
                                                                            autoComplete="off"
                                                                            onChange={handleTranChange}
                                                                        />
                                                                        <button className="btn btn-primary" style={{marginLeft:"10px"}} type="button" onClick={tranSubmit}>確認</button>
                                                                    </div>
                                                                }
                                                            </ListGroup.Item>
                                                            <ListGroup.Item>
                                                                <i className={search.訂單狀態.length > 2 ? "fa fa-check" :  search.訂單狀態.length === 2 ? "fa fa-arrow-right" : "fa fa-ellipsis-h"} style={{color: search.訂單狀態.length >= 3 ? "green" : "lightgrey"}}></i> 檢查手機
                                                                { search.訂單狀態.length > 2 ? 
                                                                    <span>{search.訂單狀態[2].時間}</span> 
                                                                    : search.訂單狀態.length === 2 ?
                                                                        <button className="btn btn-primary" style={{marginLeft:"10px"}} type="button" onClick={checkSubmit}>完成檢查</button> : <></>                                                
                                                                }
                                                            </ListGroup.Item>
                                                            <ListGroup.Item>
                                                                <i className={search.訂單狀態.length > 3 ? "fa fa-check" :  search.訂單狀態.length === 3 ? "fa fa-arrow-right" : "fa fa-ellipsis-h"} style={{color: search.訂單狀態.length >= 4 ? "green" : "lightgrey"}}></i> 維修手機
                                                                { search.訂單狀態.length > 3 ? 
                                                                    <span>{search.訂單狀態[3].時間}</span> 
                                                                    : search.訂單狀態.length === 3 ?
                                                                        <button className="btn btn-primary" style={{marginLeft:"10px"}} type="button" onClick={repairSubmit}>完成維修</button> : <></>             
                                                                }
                                                            </ListGroup.Item>
                                                            <ListGroup.Item>
                                                                <i className={search.訂單狀態.length > 4 ? "fa fa-check" :  search.訂單狀態.length === 4 ? "fa fa-arrow-right" : "fa fa-ellipsis-h"} style={{color: search.訂單狀態.length >= 5 ? "green" : "lightgrey"}}></i> 手機寄回
                                                                { search.訂單狀態.length > 4 ? 
                                                                    <>
                                                                        <span>{search.訂單狀態[4].時間}</span>
                                                                        <span>送件編號: <a href="https://htm.sf-express.com/tw/tc/dynamic_function/waybill/" target="_blank" rel="noreferrer">{search.訂單狀態[4].收件編號}</a></span>
                                                                    </> : search.訂單狀態.length === 4 ? 
                                                                    <div>
                                                                        <input
                                                                            type="text" 
                                                                            placeholder="順豐單號" 
                                                                            name="trans" 
                                                                            autoComplete="off"
                                                                            onChange={handleTranChange}
                                                                        />
                                                                        <button className="btn btn-primary" style={{marginLeft:"10px"}} type="button" onClick={tranBackSubmit}>確認</button>
                                                                    </div> : <></>                                                       
                                                                }
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
 
export default Tool;