/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { ethers } from "ethers";
import { Link } from 'react-router-dom';
import { createOrderNo, getCurrencyRate } from '../utils/utils';
import MintModal from '../Modals/mint';
import ConfirmModal from '../Modals/confirm';
import nft from '../../contract/Gotelecom.json';
import erc from '../../contract/IERC20.json';
import axios from 'axios';
import moment from 'moment';
import { getPlanDetail } from '../services/firebase';

const Price = () => {

    const [plandetail, setPlanDetail] = useState();
    const [account, setAccount] = useState();
    const [choosedPlan, setChoosedPlan] = useState();
    const [showMintModal, setShowMintModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleMintModal = () => {
        setShowMintModal((prev) => !prev);
    };

    const handleConfirmModal = () => {
        setShowConfirmModal((prev) => !prev);
    };

    const payByCrypto = async(plan) => {
        try {
            const pr = window.ethereum;
            if (!pr){
                alert("Metamask is not installed, please install!");
            } else {
                handleMintModal();
                let provider = new ethers.providers.Web3Provider(window.ethereum);
                const chainId = await provider.send("eth_chainId");
            
                if (chainId !== process.env.REACT_APP_POLYGON_MAINNET){
                    try {
                        await pr.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: ethers.utils.hexValue(process.env.REACT_APP_POLYGON_MAINNET) }],
                        });
                    } catch(switchError) {
                        if(switchError.code === 4902){
                            try {
                                await pr.request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                        { 
                                            chainId: ethers.utils.hexValue(parseInt(process.env.REACT_APP_POLYGON_CHAINID)), 
                                            chainName: process.env.REACT_APP_POLYGON_CHAIN_NAME,
                                            rpcUrls:[ process.env.REACT_APP_POLYGON_RPC_URL ],
                                            blockExplorerUrls:[ process.env.REACT_APP_POLYGON_BLOCK_URL ],  
                                            nativeCurrency: { 
                                                symbol:'MATIC',   
                                                decimals: 18
                                            }   
                                        }
                                    ]
                                });
                            } catch(e) {
                                console.log(e)
                            }
                        } else {
                            handleMintModal();
                            return
                        }
                    }
                }
                
                provider = new ethers.providers.Web3Provider(window.ethereum);
                const accounts = await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner();

                const erc20 = new ethers.Contract(process.env.REACT_APP_USDT_ADDRESS, erc.abi, provider);
                const signed_erc20 = erc20.connect(signer);

                const trans_add = process.env.REACT_APP_TRANSACTION_ADDRESS;
                if(plan === "A"){
                    const tx1 = await signed_erc20.approve(trans_add, 56000000000000000000n);
                    // const tx1 = await signed_erc20.approve(trans_add, plandetail.A.USDT);
                    await tx1?.wait();
                } else if (plan === "B"){
                    // const tx1 = await signed_erc20.approve(trans_add, 63000000000000000000n);
                    const tx1 = await signed_erc20.approve(trans_add, BigInt(plandetail.B.USDT));
                    await tx1?.wait();
                } else {
                    // const tx1 = await signed_erc20.approve(trans_add, 76000000000000000000n);
                    const tx1 = await signed_erc20.approve(trans_add, BigInt(plandetail.C.USDT));
                    await tx1?.wait();
                }
                
                const contract = new ethers.Contract(trans_add, nft.abi, provider);
                const signed_contract = contract.connect(signer);

                const nftId = Math.floor(Math.random() * 90000) + 10000;

                const tx2 = await signed_contract.mint(plan, nftId, `https://us-central1-gotelecom-nft.cloudfunctions.net/app/api/nft/${nftId}`);
                await tx2?.wait();
                
                axios({
                    method: "post",
                    url: "https://us-central1-gotelecom-nft.cloudfunctions.net/app/api/order",
                    data: {
                        orderNo: createOrderNo(),
                        nftId,
                        date: moment().format('YYYY/MM/DD HH:mm:ss'),
                        item: `NFT ${plan}`,
                        wallet: accounts[0],
                        payment: "USDT",
                        plan: plan
                    }
                }).then((res) => {
                    console.log(res.data === "OK")
                    if(res.data === "OK"){
                        handleMintModal();
                    }
                })

                console.log(accounts[0])
            }
        }
        catch(e){
            console.log(e)
            handleMintModal();
        }
    }

    const payByCredit = async(plan) => {
        const pr = window.ethereum;
        if (!pr){
            alert("Metamask is not installed, please install!");
        } else {
            // handleMintModal();
            let provider = new ethers.providers.Web3Provider(window.ethereum);
            const chainId = await provider.send("eth_chainId");
        
            if (chainId !== process.env.REACT_APP_POLYGON_MAINNET){
                try {
                    await pr.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: ethers.utils.hexValue(process.env.REACT_APP_POLYGON_MAINNET) }],
                    });
                } catch(switchError) {
                    if(switchError.code === 4902){
                        try {
                            await pr.request({
                                method: 'wallet_addEthereumChain',
                                params: [
                                    { 
                                        chainId: ethers.utils.hexValue(parseInt(process.env.REACT_APP_POLYGON_CHAINID)), 
                                        chainName: process.env.REACT_APP_POLYGON_CHAIN_NAME,
                                        rpcUrls:[ process.env.REACT_APP_POLYGON_RPC_URL ],
                                        blockExplorerUrls:[ process.env.REACT_APP_POLYGON_BLOCK_URL ],  
                                        nativeCurrency: { 
                                            symbol:'MATIC',   
                                            decimals: 18
                                        }   
                                    }
                                ]
                            });
                        } catch(e) {
                            console.log(e)
                        }
                    } else {
                        // handleModal();
                        return
                    }
                }
            }
        }
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = await provider.send("eth_requestAccounts", []);
        setAccount(accounts[0])
        setChoosedPlan(plan)
        handleConfirmModal();
    }

    useEffect(() => {
        getPlanDetail()
        .then( d => {
            setPlanDetail(d);
            console.log(d)
        })
        // setPlanDetail(getPlanDetail());
    }, []);

    return (
        <>
            <section id="price" className="price-area ptb-80 bg-f6f6f6">
            <div className="container">
                <div className="section-title">
                    <h2>方案計畫 <span>價格</span></h2>
                    <p>專為追求卓越品質與全方位體驗的您打造，專屬定制的優質體驗，讓您倍感尊貴。</p>
                </div>
            
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="pricingTable">
                            <h3 className="title">{!!plandetail && plandetail.A.名稱}</h3>
                            <div className="price-value">
                                <span className="currency">NT$ </span>
                                <span className="amount">{!!plandetail && plandetail.A.台幣價格}</span>
                                {/* <span className="month">/Per month</span> */}
                            </div>
                            <ul className="pricing-content">
                                <li><i className="fa fa-check"></i> 螢幕玻璃維修</li>
                                <li><i className="fa fa-check"></i> 更換電池（BSMI認證電池）或Face ID 維修</li>
                                <li><i className="fa fa-check"></i> 回收手機可享有800元的現金回贈</li>
                                <li><i className="fa fa-check"></i> IPhone 8, 8 Plus, X, XR, XS, XS Max</li><br></br>
                                {/* <li><i></i></li> */}
                                {/* <li><i className="fa fa-check"></i> Weekly Backups</li>
                                <li><i className="fa fa-check"></i> Support 24 / hour</li> */}
                            </ul>
                            <Link onClick={() => { payByCredit("A"); }} to="#" className="btn btn-primary" style={{marginRight:"5px"}}>信用卡付款</Link>
                            <Link onClick={() => { payByCrypto("A"); }} to="#" className="btn btn-primary">USDT付款</Link>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                        <div className="pricingTable">
                            <h3 className="title">{!!plandetail && plandetail.B.名稱}</h3>
                            <div className="price-value">
                                <span className="currency">NT$ </span>
                                <span className="amount">{!!plandetail && plandetail.B.台幣價格}</span>
                                {/* <span className="month">/Per month</span> */}
                            </div>
                            <ul className="pricing-content">
                                <li><i className="fa fa-check"></i> 螢幕玻璃維修</li>
                                <li><i className="fa fa-check"></i> 更換電池（BSMI認證電池）或Face ID 維修</li>
                                <li><i className="fa fa-check"></i> 回收手機可享有1000元的現金回贈</li>
                                <li><i className="fa fa-check"></i> 包含基礎入門所有的機型, 以及IPhone 11, 11 Pro, 11 Pro Max, 12, 12 Mini, 12 Pro, 12 Pro Max</li>
                            </ul>
                            <Link onClick={() => { payByCredit("B"); }} to="#" className="btn btn-primary" style={{marginRight:"5px"}}>信用卡付款</Link>
                            <Link onClick={() => { payByCrypto("B"); }} to="#" className="btn btn-primary">USDT付款</Link>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div className="pricingTable">
                            <h3 className="title">{!!plandetail && plandetail.C.名稱}</h3>
                            <div className="price-value">
                                <span className="currency">NT$ </span>
                                <span className="amount">{!!plandetail && plandetail.C.台幣價格}</span>
                                {/* <span className="month">/Per month</span> */}
                            </div>
                            <ul className="pricing-content">
                                <li><i className="fa fa-check"></i> 螢幕玻璃維修</li>
                                <li><i className="fa fa-check"></i> 更換電池（BSMI認證電池）或Face ID 維修</li>
                                <li><i className="fa fa-check"></i> 回收手機可享有1500元的現金回贈</li>
                                <li><i className="fa fa-check"></i> 全方位保護，任何機型都能維修</li><br></br>
                            </ul>
                            <Link onClick={() => { payByCredit("C"); }} to="#" className="btn btn-primary" style={{marginRight:"5px"}}>信用卡付款</Link>
                            <Link onClick={() => { payByCrypto("C"); }} to="#" className="btn btn-primary">USDT付款</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <MintModal
            show={showMintModal}
            handleModal={handleMintModal}
        />
        <ConfirmModal
            show={showConfirmModal}
            handleModal={handleConfirmModal}
            plan={choosedPlan}
            wallet={account}
            detail={plandetail}
        />
        </>
    );
}
 
export default Price;