import React from 'react';
 
class WhoWeAre extends React.Component {
    render(){
        return (
            <section className="who-we-are ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>為何選擇 <span>均浩電訊</span>?</h2>
                        <h3>業界最高品質的服務水準，最優惠的保固方案</h3>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-who-we-are">
                                <i className="fa fa-gear"></i>
                                <h4>BSMI認證零件</h4>
                                <p>使用優質的高效零件進行維修，以確保最佳的效果，並且也能保證產品的安全性和可靠性。</p>
                                <span>1</span>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-who-we-are">
                                <i className="fa fa-wrench"></i>
                                <h4>專業維修</h4>
                                <p>技術人員專業熟練，經過嚴格的培訓，能夠確保維修的質量和效率。</p>
                                <span>2</span>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-who-we-are">
                                <i className="fa fa-tag"></i>
                                <h4>優惠價格</h4>
                                <p>均浩電訊Gotelecom 提供最優惠的手機保固方案，同時確保我們的客戶獲得最高品質的檢修服務。</p>
                                <span>3</span>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-who-we-are">
                                <i className="fa fa-shield"></i>
                                <h4>長效保固</h4>
                                <p>均浩電訊Gotelecom提供業界最長一年的售後保固，維修完成後我們依然在乎您的愛機使用狀況。</p>
                                <span>4</span>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-who-we-are">
                                <i className="fa fa-flash"></i>
                                <h4>方便快捷</h4>
                                <p>除了台北實體門市外，我們提供零接觸服務，您可於線上一鍵申請快遞收取，亦可藉由全家FamilyPort的手機回收服務，我們將快速收取您的愛機為您提供檢修服務。</p>
                                <span>5</span>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-who-we-are">
                                <i className="fa fa-phone"></i>
                                <h4>專屬客服</h4>
                                <p>我們擁有專業且熱情的客服團隊，隨時為客戶提供協助。我們的客服團隊熟悉產品以及服務，並且有能力解決客戶的任何問題。</p>
                                <span>6</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default WhoWeAre;