import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { RotatingLines } from  'react-loader-spinner'
import { addRecycleOrder} from "../../services/firebase";
import { createRecycleNo } from "../../utils/utils";
import axios from "axios";
import moment from "moment";
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from "react-hook-form";
import { ethers } from "ethers";
import { getMyNFTsforRecycle } from '../../services/web3';
import DatePicker from "react-datepicker";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


const RecycleModal = ({ show, handleModal, data, clear, plan}) => {
   
    const [state, setState] = useState(false);
    const { register, handleSubmit, watch, setValue, formState: { errors }, reset } = useForm();
    const [account, setAccount] = useState(null);
    const [nfts, setNfts] = useState();
    const [startDate, setStartDate] = useState();
    const [repairNo, setRepairNo] = useState();
    const addRecycle = async (order) => {
        const no = createRecycleNo();
        setRepairNo(no);
        const res = await addRecycleOrder(no, order, checked);
        // axios.post("https://getform.io/f/3358602a-6b8f-4e78-af98-fa89931e2fbb", {
        //     預約編號: order.no,
        //     預約人: order.firstName,
        //     信箱: order.email,
        //     電話: order.phone,
        //     IMEI: order.imei,
        //     手機型號: order.phone_type,
        //     維修方案: plan,
        //     維修項目: order.repair.join(', '),
        //     收件地址: order.address,
        //     收件時間: moment(data.datetime).format('YYYY/MM/DD H:mm A')
        // })
        console.log(res)
        setState(true)
    };

    const connectToMetamask = async() => {
        // handleMintModal();
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        let accounts = await provider.send("eth_requestAccounts", []);

        console.log(accounts)
        provider.on('accountsChanged', function (accounts) {
            setAccount(accounts[0]);
        });

        setAccount(accounts[0]);
        // setNfts(await getMyNFTs(accounts[0]))
    }

    const getNFTid = () => {
        return nfts.map((nft, index) => {
            return <option key={index} value={nft}>{nft} </option>;
        });
    }

    // window.ethereum.on('accountsChanged', async function (accounts) {
    //     // setNfts();
    //     window.location.reload(true);
    //     // setAccount(accounts[0]);
    // })

    const close = () => {
        reset();
        setAccount(null);
        setNfts();
        setChecked(false);
        setStartDate();
        setRepairNo();
        handleModal();
    }

    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const onSubmit = async (data) => {
        addRecycle(data);
        console.log(data);
    };

    
    useEffect(() => {
        if(!!account){
            setNfts();
            console.log(account);
            getMyNFTsforRecycle(account)
            .then( res => {
                setNfts(res)
            })
        }     
    }, [account])

    return (
        !state ? 
            <Modal
                className="rn-popup-modal placebid-modal-wrapper"
                show={show}
                centered
                onHide={close}
            >
                <Modal.Header>
                    <h3 className="modal-title">回收預約確認</h3>
                </Modal.Header>
                <Modal.Body>
                <div className="contact-form">
                            <h4>預約回收</h4>
                            <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <FormControlLabel
                                        value="nft"
                                        control={<Checkbox checked={checked} onChange={handleChange}/>}
                                        label="使用 智能合約"
                                        labelPlacement="使用 智能合約"
                                    />
                                    { checked &&
                                        <>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="wa">錢包地址</label>
                                                    {/* { !!account && 
                                                        <button className="btn btn-primary" type="button" style={{marginLeft:"10px", marginBottom:"20px"}} onClick={switchAccount}>switch account</button>
                                                    } */}
                                                    { !!!account ?
                                                        <button className="btn btn-primary" type="button" style={{marginLeft:"10px", marginBottom:"20px"}} onClick={connectToMetamask} >Connect</button> :
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            name="wallet" 
                                                            id="wallet" 
                                                            required={true}
                                                            data-error="Please enter your name" 
                                                            value={account}
                                                            autoComplete="new-password"
                                                            {...register("wallet")}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            { !!account &&
                                                <>
                                                    <div className="col-lg-12 col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="nft">智能合約 編號</label>
                                                            <select className="form-control" defaultValue={'default'} {...register("nftId", { onChange: (e) => {} })}>
                                                                <option disabled={true} value="default">
                                                                    -- 選擇 智能合約 --
                                                                </option>
                                                                { !!nfts && getNFTid() }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <div style={{display: "flex"}}>
                                                <label htmlFor="firstName">姓名</label>
                                                <ErrorMessage errors={errors} name="firstName" as="p" style={{marginBottom:"5px"}}/>
                                            </div>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="firstName" 
                                                id="firstName" 
                                                data-error="Please enter your name" 
                                                {...register("firstName", {
                                                    required: "＊必填"
                                                })}
                                                autoComplete="new-password"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <div style={{display: "flex"}}>
                                                <label htmlFor="email">電子信箱</label>
                                                <ErrorMessage errors={errors} name="email" as="p" style={{marginBottom:"5px"}}/>
                                            </div>
                                            <input 
                                                type="email" 
                                                className="form-control" 
                                                name="email" 
                                                id="email" 
                                                data-error="Please enter your email" 
                                                {...register("email", {
                                                    required: "＊必填"
                                                })}
                                                autoComplete="new-password"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <div style={{display: "flex"}}>
                                                <label htmlFor="number">電話號碼</label>
                                                <ErrorMessage errors={errors} name="phone" as="p" style={{marginBottom:"5px"}}/>
                                            </div>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="number" 
                                                id="number" 
                                                data-error="Please enter your number" 
                                                {...register("phone", {
                                                    required: "＊必填"
                                                })}
                                                autoComplete="new-password"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <div style={{display: "flex"}}>
                                                <label htmlFor="address">收件地址</label>
                                                <ErrorMessage errors={errors} name="address" as="p" style={{marginBottom:"5px"}}/>
                                            </div>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="address" 
                                                id="address" 
                                                data-error="Please enter your address" 
                                                {...register("address",{
                                                    required: "＊必填"
                                                })}
                                                autoComplete="new-password"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <div style={{display: "flex"}}>
                                                <label htmlFor="number">收件時間</label>
                                                <ErrorMessage errors={errors} name="datetime" as="p" style={{marginBottom:"5px"}}/>
                                            </div>
                                            <DatePicker
                                                {...register("datetime", { required: "＊必填" })}
                                                name="datetime"
                                                className={"form-control"}
                                                selected={startDate}
                                                onChange={val => {
                                                    setStartDate(val);
                                                    setValue("datetime", val);
                                                }}
                                                showTimeSelect
                                                timeFormat="HH:mm a"
                                                timeIntervals={15}
                                                timeCaption="time"
                                                dateFormat="MM-dd-yyyy h:mm a"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="btn btn-primary">確認</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                </Modal.Body>
            </Modal>
        : 
        <Modal
                className="rn-popup-modal placebid-modal-wrapper"
                show={show}
                centered
                onHide={close}
            >
                <Modal.Header>
                    <h3 className="modal-title">回收預約成功</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="bid-content-mid">
                        <h6>預約編號: {repairNo}</h6>
                        <h6>預約電話: {watch("phone")}</h6>
                    </div>
                    <div className="d-grid gap-2 col-6 mx-auto">
                        <a href="/">
                            <button type="button" onClick={() => {clear();}} className="btn btn-primary">回到首頁</button>
                        </a>                   
                    </div>
                </Modal.Body>
            </Modal>
    ) 
}

RecycleModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleModal: PropTypes.func.isRequired,
};
export default RecycleModal;
