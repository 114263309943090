import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// import Particles from 'react-particles-js';

// const particleOpt = {
//     particles: {
//         number: {
//             value: 160,
//             density: {
//                 enable: false
//             }
//         },
//         size: {
//             value: 10,
//             random: true
//         },
//         move: {
//             direction: "bottom",
//             out_mode: "out",
//             speed: 2,
//         },
//         line_linked: {
//             enable: false
//         }
//     },
//     interactivity: {
//         events: {
//             onclick: {
//                 enable: true,
//                 mode: "repulse"
//             }
//         },
//         modes: {
//             repulse:{
//                 distance: 200,
//                 duration: 0.4
//             }
//         }
//     }
// }
 
class MainBanner extends React.Component {
    render(){
        return (
            <div id="home1" className="main-banner item-bg-one">
                {/* <Particles
                    params={{...particleOpt}}
                /> */}
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="main-banner-text">
                                        <h4>WELCOME TO GOTELECOM</h4>
                                        <h1><span>均浩電訊</span></h1>
                                        <h1>讓維修變得更簡單</h1>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                                        <AnchorLink href="#welcome" offset={85} className="btn btn-primary">馬上開始</AnchorLink>
                                        {/* <AnchorLink href="#work" className="btn btn-primary view-work">View Work</AnchorLink> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default MainBanner;