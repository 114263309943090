// import Web3 from "web3";
// import { config as loadEnv } from 'dotenv';
import { Network, Alchemy } from "alchemy-sdk";
import { checkNftOwners, checkNftRecycle } from "../firebase";
// import abi from "../../../contract/Gotelecom.json"
// const NameContract = web3.eth.Contract(contract_abi, contract_address);

// Optional Config object, but defaults to demo api-key and eth-mainnet.
const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY, // Replace with your Alchemy API Key.
    network: Network.MATIC_MAINNET, // Replace with your network.
};


const alchemy = new Alchemy(settings);

export const getMyNFTs = async(address) => {

    
    console.log(settings)
    try{
        const nfts = await alchemy.nft.getNftsForOwner(address, {contractAddresses: [process.env.REACT_APP_NFT_ADDRESS]});
        console.log(nfts)
        const res = await checkNftOwners(nfts.ownedNfts);
        return res;
    } catch(e) {
        console.log(e)
    }
}

export const getMyNFTsforRecycle = async(address) => {

    
    console.log(settings)
    try{
        const nfts = await alchemy.nft.getNftsForOwner(address, {contractAddresses: [process.env.REACT_APP_NFT_ADDRESS]});
        console.log(nfts)
        const res = await checkNftRecycle(nfts.ownedNfts);
        return res;
    } catch(e) {
        console.log(e)
    }
}

export const getMetdata = async() => {
    const res = await alchemy.nft.getNftMetadata(
        "0xFfc35Ee6D9BceDaa1e64b97DfEaE934e6AF56169",
        "746438925002",
        {refreshCache: true}
    )
    console.log("Metadata: ", res);
}