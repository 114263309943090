import React from 'react';
// import { Link } from 'react-router-dom';
import imgOne from '../../assets/images/repair/electronic-technician-holds-two-identical-smartphones-comparison-one-hand-broken-another-new.jpg';
import imgTwo from '../../assets/images/repair/close-up-hands-service-worker-repairing-modern-smarphone.jpg';
import imgThree from '../../assets/images/repair/go1.jpg';
// import imgFour from '../../assets/images/different-img4.jpg';
 
class WhyWeDifferent extends React.Component {

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-panel");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" checked", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += " checked";
    }

    render(){
        return (
            <section id="about" className="why-we-different ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>關於 <span>均浩電訊</span></h2>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                    </div>
                
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="tabset">
                                {/* <!-- Tab 1 --> */}
                                <label 
                                    onClick={(e) => this.openTabSection(e, 'speed-flexibility')} 
                                    className="tablinks checked" 
                                    name="tabset" 
                                    id="tab1" 
                                    aria-controls="speed-flexibility"  
                                    htmlFor="tab1"
                                >
                                    快速、可靠
                                </label>
                                {/* <!-- Tab 2 --> */}
                                <label 
                                    onClick={(e) => this.openTabSection(e, 'profeesional-work')} 
                                    className="tablinks" 
                                    name="tabset" 
                                    id="tab2" 
                                    aria-controls="profeesional-work"  
                                    htmlFor="tab2"
                                >
                                    專業維修
                                </label>
                                {/* <!-- Tab 3 --> */}
                                <label 
                                    onClick={(e) => this.openTabSection(e, 'experienced-staff')} 
                                    className="tablinks" 
                                    name="tabset" 
                                    id="tab3" 
                                    aria-controls="experienced-staff"  
                                    htmlFor="tab3"
                                >
                                    在線客服
                                </label>

                                <div className="tab-panels">
                                    <section id="speed-flexibility" className="tab-panel" style={{display: "block"}}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>快速、可靠、更值得信賴</h4>
                                                    <p>選擇均浩電訊，讓您的手機問題迎刃而解！</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>一鍵預約檢修，快速、便捷</li>
                                                        <li><i className="fa fa-check"></i>採用符合國家BSMI認證高品質的維修零件</li>
                                                        <li><i className="fa fa-check"></i>採用業界最新的技術服務</li>
                                                        <li><i className="fa fa-check"></i>保證同業最高價收購您的愛機</li>
                                                        <li><i className="fa fa-check"></i>提供零接觸服務，快遞上門取件、送件</li>
                                                    </ul>
                                                    
                                                    {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgOne} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    
                                    <section id="profeesional-work" className="tab-panel">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>專業維修，給您放心！</h4>
                                                    <p>均浩電訊，專注於手機維修多年，深諳各種手機問題，提供高品質維修服務，讓你的手機恢復如新！</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>我們擁有豐富的手機維修技術和知識，能夠解決各種手機問題。</li>
                                                        <li><i className="fa fa-check"></i>提供高效的維修服務，讓你的手機迅速恢復運作。</li>
                                                        <li><i className="fa fa-check"></i>細心檢查每一個細節，確保每一個手機問題得到完美解決。</li>
                                                        <li><i className="fa fa-check"></i>擁有嚴格的品質控制，讓你的手機維修後品質穩定可靠。</li>
                                                    </ul>
                                                    
                                                    {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgTwo} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    
                                    <section id="experienced-staff" className="tab-panel">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>均浩電訊，專屬客服，讓你體驗不一樣的服務！</h4>
                                                    <p>我們提供專屬客服，為你解答手機問題，讓你的使用體驗更加順暢！</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>為每一位客戶分配專屬的客服，根據客戶的需求和習慣進行個性化對接和服務。</li>
                                                        <li><i className="fa fa-check"></i>全天候的客戶支援，隨時隨地為客戶解決手機問題。</li>
                                                        <li><i className="fa fa-check"></i>24小時內給客戶回應、快速解決問題、減少客戶等待的時間。</li>
                                                        <li><i className="fa fa-check"></i>擁有豐富的手機知識和維修經驗，提供專業的解答和建議，讓客戶獲得最佳體驗。</li>
                                                        <li><i className="fa fa-check"></i>堅持誠信經營，以客戶滿意為首要目標。</li>
                                                        <li><i className="fa fa-check"></i>不斷提升服務品質，推出更多優惠活動和禮品，回饋客戶的支持和愛護。</li>
                                                    </ul>
                                                    
                                                    {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgThree} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default WhyWeDifferent;