import React from 'react';
import 'isomorphic-fetch';
import { Link } from 'react-router-dom';

class Contact extends React.Component {
    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields: {
            name: '',
            email: '',
            phone: '',
            text: ''
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const data = this.state.formFields;
        fetch('/api/contact', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 200) {
                this.setState({ submitted: true })
            }
            let formFields = Object.assign({}, this.state.formFields);
            formFields.name = '';
            formFields.email = '';
            formFields.phone = '';
            formFields.text = '';
            this.setState({formFields});
        });
    }

    nameChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.name = e.target.value;
        this.setState({formFields});
    }

    emailChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.email = e.target.value;
        this.setState({formFields});
    }

    phoneChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.phone = e.target.value;
        this.setState({formFields});
    }

    textChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.text = e.target.value;
        this.setState({formFields});
    }

    onHideSuccess = () => {
        this.setState({submitted: false});
    }

    successMessage = () => {
        if (this.state.submitted){
            return (
                <div 
                    className="alert alert-success alert-dismissible fade show" 
                    style={{ marginTop: '20px', marginBottom: '0' }}
                >
                    <strong>Thank you!</strong> Your message is send to the owner.
                    <button 
                        type="button" 
                        className="btn-close"
                        onClick={this.onHideSuccess}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        }
    }

    render(){
        return (
            <section id="contact" className="contact-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        {/* <h4>Get in Touch</h4> */}
                        <h2>聯絡 <span>我們</span> </h2>
                        <p>聯絡我們如果您有任何疑問</p>
                        {/* <Link to="/reserve" className="btn btn-primary">Read More</Link> */}
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                      
                            <iframe 
                                title="Google Map" 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.4808338177995!2d121.55768151118208!3d25.085579777690317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ac6b563240ed%3A0xacd2de358124bb90!2zMTE05Y-w5YyX5biC5YWn5rmW5Y2A5paH5rmW6KGXMuiZnw!5e0!3m2!1szh-TW!2stw!4v1682670973443!5m2!1szh-TW!2stw"
                                width="100%" 
                                height="392" 
                                loading="lazy"
                            ></iframe>
                            
                            <div className="contact-info">
                                <ul>
                                    <li>
                                        <i className="fa fa-map-marker"></i>地址: 台北市內湖區文湖街2號

                                    </li>
                                    {/* <li>
                                        <i className="fa fa-envelope"></i> 
                                        <a href="mailto:addax@gmail.com">addax@gmail.com</a>
                                    </li> */}
                                    <li>
                                        <i className="fa fa-phone"></i> 
                                        <a href="tel:124412-2445515">電話: 0901 261 888</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-fax"></i> 
                                        <a href="tel:617-241-60055">LINE: @twgotelecom</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Contact;