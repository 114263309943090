import React from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import aboutImg from '../../assets/images/about.jpg';
 
class About extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        return (
            <section id="about" className="about-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>關於 <span>均浩電訊</span></h2>
                        <p>讓維修變得更簡單</p>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-addax">
                                <div className="section-title">
                                    <h2>We Are Digital <span>Marketing & Branding</span></h2>
                                    <p>均浩電訊香港有限公司成立於2006年，是一家專業的智能手機批發商。我們專注於進出口高品質的智能手機，並提供優質的手機維修服務。在過去的數年裡，我們一直致力於提供最好的產品和服務，並以其優良的信譽和專業知識，在業界中建立了良好的口碑。我們相信，通過不斷創新和提供最優質的服務，我們能夠持續成長和發展，共創美好未來。</p>
                                </div>

                                <ul className="pull-left">
                                    <li><i className="fa fa-check"></i>持續創新，提供優質服務</li>
                                    <li><i className="fa fa-check"></i>深耕手機產業20年</li>
                                    <li><i className="fa fa-check"></i>良好口碑、品質保證</li>
                                </ul>

                                {/* <ul>
                                    <li><i className="fa fa-check"></i>Modern Design</li>
                                    <li><i className="fa fa-check"></i>Awesome Design</li>
                                    <li><i className="fa fa-check"></i>Digital Marketing & Branding</li>
                                </ul> */}

                                <Link to="#" className="btn btn-primary">Read More</Link>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="about-video">
                                <img src={aboutImg} alt="about" />
                                <div className="video-btn">
                                    <Link 
                                        onClick={e => {e.preventDefault(); this.openModal()}}
                                        to="#" 
                                        className="popup-youtube"
                                    >
                                        <i className="fa fa-play"></i>
                                    </Link>
                                    <ModalVideo 
                                        channel='youtube' 
                                        isOpen={this.state.isOpen} 
                                        videoId='bk7McNUjWgw' 
                                        onClose={() => this.setState({isOpen: false})} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;