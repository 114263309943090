import React from 'react';
import imgOne from '../../assets/images/repair/services-img1.jpg';
import imgTwo from '../../assets/images/repair/services-img2.jpg';
import imgThree from '../../assets/images/repair/services-img3.jpg';

class Services extends React.Component {
    render(){
        return (
            <section id="services" className="services-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>我們的 <span>服務</span></h2>
                        <p>均浩電訊Gotelecom，專業手機維修服務，為您的手機注入新生命！我們提供一站式手機維修服務，包含玻璃修復、電池更換或Face ID維修，並主打同業手機回收最高價，讓您的愛機得到全面的照顧。</p>
                    </div>
                
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services">
                                <div className="services-img">
                                    <img src={imgOne} alt="services-img" />
                                    
                                    <div className="icon">
                                        <i className="fa fa-mobile"></i>
                                    </div>
                                </div>
                                
                                <div className="services-content">
                                    <h3>玻璃維修</h3>
                                    <p>使用高品質的玻璃材料進行修復，顯示效果更佳，讓你的手機看起來更清晰、更明亮。</p><br></br>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services">
                                <div className="services-img">
                                    <img src={imgTwo} alt="services-img" />
                                    
                                    <div className="icon">
                                        <i className="fa fa-battery"></i>
                                    </div>
                                </div>
                                
                                <div className="services-content">
                                    <h3>電池更換或Face ID 維修</h3>
                                    <p>使用BSMI認證電池，更換後可以提高手機的電池整體性能，讓你的手機運行更流暢、更快速。</p><br></br>
                                </div>
                            </div>
                        </div>    
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-services">
                                <div className="services-img">
                                    <img src={imgThree} alt="services-img" />
                                    
                                    <div className="icon">
                                        <i className="fa fa-recycle"></i>
                                    </div>
                                </div>
                                
                                <div className="services-content">
                                    <h3>現金回收</h3>
                                    <p>我們的現金高價回收計劃不僅能夠讓你賺取額外的現金，還能夠對環境做出貢獻。智能合約持有人在回收後更可獲得800/1000/1500元現金回贈。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Services;