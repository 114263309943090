import React, { useMemo, useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import imgOne from "../../assets/images/repair/electronic-technician-holds-two-identical-smartphones-comparison-one-hand-broken-another-new.jpg";
import imgTwo from "../../assets/images/repair/close-up-hands-service-worker-repairing-modern-smarphone.jpg";
import imgThree from "../../assets/images/repair/go1.jpg";
// import imgFour from '../../assets/images/different-img4.jpg';
import ListGroup from "react-bootstrap/ListGroup";
import MaterialReactTable from "material-react-table";
import { MdPhoneIphone } from 'react-icons/md';
import { TbDeviceIpad } from 'react-icons/tb';
import {
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    Typography,
    TextField,
} from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import data from "../data/recycle_price.json"
import { getRecyclePrice } from "../services/firebase";
import RecycleDetailModal from "../Modals/recycle-detail"
import RecycleModal from "../Modals/recycle"
import { createOrder, demsg } from "../services/sf";

const Recycle = () => {
  const openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tab-panel");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" checked", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    evt.currentTarget.className += " checked";
  };

  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorKey: "product",
        header: "你的裝置",
      },
      {
        accessorKey: "price",
        header: "預估折抵價值",
        Cell: ({ cell, row }) =>
            <span>最高達 <strong>&nbsp;NT${cell.getValue().toLocaleString()}</strong></span>  
      }, 
      {
        accessorKey: "level",
        Cell: ({cell, row}) => {
          // <span>{cell.getValue()}</span>
        }
      }
    ],
    []
  );

  const [detail, setDetail] = useState()
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showRecycleModal, setShowRecycleModal] = useState(false);

  const [showDetail, setShowDetail] = useState();

  const handleDetailModal = (row) => {
      setShowDetailModal((prev) => !prev);
      setShowDetail(row);
  };
  
  const handleRecycleModal = (row) => {
    setShowRecycleModal((prev) => !prev);
    // setShowDetail(row);
  };

  useEffect(() => {
    getRecyclePrice()
    .then(
      d => {
        setDetail(d)
      }
    )
  }, []);
  return (
    <>
    <section
      // id="about"
      className="subscribe-area ptb-80 bg-f6f6f6"
      style={{ textAlign: "center" }}
    >
      <div className="container">
        <div className="section-title-1">
          <h2>
            <span>回收方案</span>
          </h2>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="tabset">
              {/* <!-- Tab 1 --> */}
              <label
                onClick={(e) => openTabSection(e, "speed-flexibility")}
                className="tablinks checked"
                name="tabset"
                id="tab1"
                aria-controls="speed-flexibility"
                htmlFor="tab1"
              >
                <MdPhoneIphone style={{marginBottom:"3px"}}/> iPhone
              </label>
              {/* <!-- Tab 2 --> */}
              <label
                onClick={(e) => openTabSection(e, "profeesional-work")}
                className="tablinks"
                name="tabset"
                id="tab2"
                aria-controls="profeesional-work"
                htmlFor="tab2"
              >
                <TbDeviceIpad style={{marginBottom:"3px"}}/> iPad
              </label>
              {/* <!-- Tab 3 --> */}
              <label
                onClick={(e) => openTabSection(e, "experienced-staff")}
                className="tablinks"
                name="tabset"
                id="tab3"
                aria-controls="experienced-staff"
                htmlFor="tab3"
              >
                其他裝置
              </label>
              <div className="tab-panels">
                <section
                  id="speed-flexibility"
                  className="tab-panel"
                  style={{ display: "block" }}
                >
                  <div className="" style={{padding:"0 200px 0 200px"}}>
                    <div className="col-lg-12 col-md-12">
                      <div className="why-we-different-text">
                        <MaterialReactTable
                          columns={columns}
                          data={!!detail && detail.iPhone}
                          enableColumnActions={false}
                          enableColumnFilters={false}
                          enablePagination={false}
                          enableSorting={false}
                          enableBottomToolbar={false}
                          enableTopToolbar={false}
                          enableRowActions
                          muiTableBodyRowProps={{ hover: false }}
                          initialState={{ columnVisibility: { level: false } }}
                          renderRowActionMenuItems={({ closeMenu, row}) => [
                            <MenuItem
                              key={0}
                              onClick={() => {
                                // View profile logic...
                                // alert(row.id)
                                // console.log("Level: ",data[row.id]["level"])
                                handleDetailModal(row.original);
                                closeMenu();
                              }}
                              // sx={{ m: 0 }}
                            >
                              <ListItemIcon>
                                <AccountCircle />
                              </ListItemIcon>
                              瞭解更多
                            </MenuItem>,
                            <MenuItem
                              key={1}
                              onClick={() => {
                                // Send email logic...
                                handleRecycleModal()
                                closeMenu();
                              }}
                              sx={{ m: 0 }}
                            >
                              <ListItemIcon>
                                <Send />
                              </ListItemIcon>
                              預約回收
                            </MenuItem>,
                          ]}
                          positionActionsColumn="last"
                        />
                      </div>
                    </div>
                  </div>
                </section>

                <section id="profeesional-work" className="tab-panel">
                    <div className="" style={{padding:"0 300px 0 300px"}}>
                        <div className="col-lg-12 col-md-12">
                            <div className="why-we-different-text">
                            <MaterialReactTable
                          columns={columns}
                          data={!!detail && detail.iPad}
                          enableColumnActions={false}
                          enableColumnFilters={false}
                          enablePagination={false}
                          enableSorting={false}
                          enableBottomToolbar={false}
                          enableTopToolbar={false}
                          enableRowActions
                          muiTableBodyRowProps={{ hover: false }}
                          renderRowActionMenuItems={({ closeMenu }) => [
                            <MenuItem
                              key={0}
                              onClick={() => {
                                // View profile logic...
                                closeMenu();
                              }}
                              sx={{ m: 0 }}
                            >
                              <ListItemIcon>
                                <AccountCircle />
                              </ListItemIcon>
                              瞭解更多
                            </MenuItem>,
                            <MenuItem
                              key={1}
                              onClick={() => {
                                // Send email logic...
                                closeMenu();
                              }}
                              sx={{ m: 0 }}
                            >
                              <ListItemIcon>
                                <Send />
                              </ListItemIcon>
                              預約回收
                            </MenuItem>,
                          ]}
                          positionActionsColumn="last"
                        />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="experienced-staff" className="tab-panel">
                    <div className="" style={{padding:"0 300px 0 300px"}}>
                    <div className="col-lg-12 col-md-12">
                      <div className="why-we-different-text">
                      <MaterialReactTable
                          columns={columns}
                          data={!!detail && detail.others}
                          enableColumnActions={false}
                          enableColumnFilters={false}
                          enablePagination={false}
                          enableSorting={false}
                          enableBottomToolbar={false}
                          enableTopToolbar={false}
                          enableRowActions
                          muiTableBodyRowProps={{ hover: false }}
                          renderRowActionMenuItems={({ closeMenu }) => [
                            <MenuItem
                              key={0}
                              onClick={() => {
                                // View profile logic...
                                closeMenu();
                              }}
                              sx={{ m: 0 }}
                            >
                              <ListItemIcon>
                                <AccountCircle />
                              </ListItemIcon>
                              瞭解更多
                            </MenuItem>,
                            <MenuItem
                              key={1}
                              onClick={() => {
                                // Send email logic...
                                closeMenu();
                              }}
                              sx={{ m: 0 }}
                            >
                              <ListItemIcon>
                                <Send />
                              </ListItemIcon>
                              預約回收
                            </MenuItem>,
                          ]}
                          positionActionsColumn="last"
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      <RecycleDetailModal
          show={showDetailModal}
          handleModal={handleDetailModal}
          data={showDetail}
      />
      <RecycleModal
        show={showRecycleModal}
        handleModal={handleRecycleModal}
      />
    </>
  );
};

export default Recycle;
